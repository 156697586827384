/* eslint-disable no-use-before-define */
import React, { createContext } from 'react'
import { styled } from '@mui/material/styles'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Drawer  } from '@mui/material'
// ICONS
import { ReactComponent as FullLogoSvg } from 'src/assets/icons/Full_logo.svg'
import UserMenu from 'src/components/UserMenu/UserMenu'
import useAuth from 'src/hooks/useAuth'
import NavItem from './NavItem'
import {languageStorageKey, language} from 'src/i18n'

const PREFIX = 'NavBar'

const classes = {
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  rightSpacer: `${PREFIX}-rightSpacer`,
  root: `${PREFIX}-root`,
  scrollbar: `${PREFIX}-scrollbar`,
  logo: `${PREFIX}-logo`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.desktopDrawer}`]: {
    overflow: 'visible',
  },

  [`& .${classes.rightSpacer}`]: {
    width: '100px',
  },

  [`& .${classes.root}`]: {
    padding: '5px',
  },

  [`& .${classes.scrollbar}`]: {
    overflow: 'visible!important',
    height: '100%',
  },

  [`& .${classes.logo}`]: {
    width: 103,
    marginRight: '20px',
  },
}))

export const NavbarContext = createContext<{ menuExpanded: boolean }>({ menuExpanded: false })

const NavBar = (): JSX.Element => {

  const { user } = useAuth()

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <div className={classes.rightSpacer}/>
      <FullLogoSvg className={classes.logo}/>
      <PerfectScrollbar
        className={classes.scrollbar}
        options={{ suppressScrollX: true, suppressScrollY: true }}
      >
        <Box className={classes.root}>
          <NavItem
            depth={0}
            hideTooltip={true}
            href={'/'}
            title={`${user?.name.length < 15 ? user?.name : user?.name.substring(0, 15)+'...' }`}
            userName={user?.name}
            content={(): JSX.Element => <UserMenu name={user?.name} email={user?.email} language={language} languageStorageKey={languageStorageKey} position="top" />}
            position={'right'}
            showArrow={true}
          />
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    (<Root>
      <Drawer
        anchor="top"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        <NavbarContext.Provider value={{ menuExpanded: false }}>
          {content}
        </NavbarContext.Provider>
      </Drawer>
    </Root>)
  )
}

export default NavBar
