import React from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { ReactSVG } from 'react-svg'
import styledComp, { css } from 'styled-components'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'

const PREFIX = 'Icon'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledSkeleton = styled(Skeleton)((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {
    position: 'relative',
    top: '3px',
  },
}))

const StyledSVGIcon = styledComp(ReactSVG)`
  svg {
      ${({ size }) =>
    size &&
        css`
          width: ${size}px;
          height: ${size}px;
        `}
    path {
      ${({ color }) =>
    color &&
        css`
          stroke: ${color};
          stroke-width: 2;
        `}
    }
  }
`

export interface IconProps {
   /** @description  custom classes */
  className?: string
  /** @description  the SVG file name , (filter name without spaces) */
  name: string
  /** @description , if true  the file located inside icons folder , (null||false) base folder will be icons/filters  */
  root?: boolean
  /** @description if true applying the root class, else  don't apply any class */
  isClassNeeded?: boolean
  /** @description   svg paths colors  */
  color?: string
  /** @description Indicates if card is still loading */
  loading?: boolean
   /** @description Indicates size of icon */
  size?: number
}

/** @description \
 * \
 * 1.load svg icon dynamically, \
 * 2.this allows change svg properties such as changing color \
 * 3.svg property can be passed as props (example color="red") \
 * 4.icon path is assets/icons/filters/{name}.svg \

 * @example ``` <UrIcon name="IP" color="white" />```
 *   */

const Icon: FC<IconProps> = (props) => {

  const name = props.name.split(' ').join('')
  const path = props.root ? 'assets/icons' : 'assets/icons/filters'
  const srcPath = `${path}/${name}.svg`

  let src
  try {
    src = require(`src/${srcPath}`)
  } catch(error) {
    src = require(`src/assets/icons/filters/CustomIcon.svg`)
  }
  const icon = <StyledSVGIcon
    data-testid={name}
    src={`${src.default ? src.default : src}`}
    color={props.color}
    size={props?.size}
    className={props.isClassNeeded ? clsx(classes.root, props.className) : props.className}
  />

  return props.loading ?
    <StyledSkeleton variant="circular" height={props?.size ? props.size : 24} width={props?.size ? props.size: 24}>{icon}</StyledSkeleton>
    : icon
}

export default Icon
