import React from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@mui/material'
import type { Store } from 'src/types/auth'
import type { FC } from 'react'
import extractFirstTwoLettersOfWords from 'src/utils/extractFirstTwoLettersOfWords'
import TextIcon from 'src/components/TextIcon/TextIcon'
import Checkmark from 'src/components/Checkmark/Checkmark'

interface SimpleSelectProps {
  selected: Store
  options: Store[]
  onChange: (tenant: Store) => void
}

const SimpleSelect: FC<SimpleSelectProps> = ({ selected, options, onChange }) => {
  const handleToggle = (value: string) => () => onChange(value)

  return (
    <Box sx={{ minWidth: 120 }}>
      <List component="nav" aria-label="select projects">
        {options.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value}`
          const initials = extractFirstTwoLettersOfWords(value)
          return ( <ListItem key={value} button onClick={handleToggle(value)}>
            <ListItemIcon>
              <TextIcon content={initials} width="25px"/>
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${value}`} />
            <ListItemSecondaryAction data-testid={value}>
              <Checkmark visible={value === selected}/>
            </ListItemSecondaryAction>
          </ListItem>)
        })}
      </List>
    </Box>
  )
}

export default SimpleSelect
