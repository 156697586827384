import React, { Suspense, Fragment, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import AuthGuard from 'src/components/AuthGuard/AuthGuard'
import AuthLayout from './layouts/AuthLayout'

import { ReactComponent as StorefrontIcon } from 'src/assets/icons/StorefrontIcon.svg'
import { ReactComponent as ComponentIcon } from 'src/assets/icons/DevTools.svg'
import { ReactComponent as Plans } from 'src/assets/icons/ProductTag.svg'
import { ReactComponent as PageSettingsIcon } from 'src/assets/icons/Drag.svg'
import SettingsIcon from '@mui/icons-material/Settings'
import { ReactComponent as HomeIcon } from 'src/assets/icons/Home.svg'
import { ReactComponent as StoreIntegrationsIcon } from 'src/assets/icons/IntegrationsIcon.svg'
import CampaignStoreGuard from './components/CampaignStoreGuard'
import SdxLoading from './components/SdxLoading/SdxLoading'
import { ShopifyAppType } from './config'
import { LDFlagSet } from 'launchdarkly-react-client-sdk'

export type RouteId = '404'
  | 'home'
  | 'app'
  // | 'auth'
  | 'help'
  | 'store_create'
  | 'store_edit'
  | 'stores'
  | 'store_analytics'
  | 'components'
  | 'component_create'
  | 'component_edit'
  | 'shopify_settings'
  | 'page_settings'
  | 'store_settings'
  | 'analytics'
  | 'shopify_plans'
  | '*'
  | '/'
  | 'validate_plan'
  | 'view_shopify_plans'
  | 'store_integrations'
  | 'view_shopify_plans_web'

export type SdxRoute = {
  exact?: boolean
  path?: string
  component?: any
  guard?: React.ComponentType
  layout?: React.ComponentType
  routes?: SdxRoute[]
  id?: RouteId
}

type PlatformName = 'shopify' | 'standalone'

export type NavbarRoute = {
  id: RouteId
  title: string
  flag?: string
  icon?: any
  platform?: {name: PlatformName[], type?: ShopifyAppType[]}
  href?: string
}
export const getNavbarRoutes = (flag: LDFlagSet, platformName: PlatformName, appType?: ShopifyAppType): NavbarRoute[] => {
  const items: NavbarRoute[] = [
    {id: 'components', title: 'Components', flag: 'campaign_stores', icon: ComponentIcon},
    {id: 'page_settings', title: 'Page Settings', flag: 'campaign_stores', icon: PageSettingsIcon},
    {id: 'store_integrations', title: 'Store Integrations', flag: 'campaign_stores', icon: StoreIntegrationsIcon},
    {id: 'store_settings', title: 'Set Up', icon: SettingsIcon},
    {id: 'shopify_settings', title: 'Settings', icon: SettingsIcon, platform: {name: ['shopify'], type: ['SALES_CHANNEL']}},
    {id: 'view_shopify_plans', title: 'Plans', icon: Plans, flag: 'plan_selection', platform: {name: ['shopify'], type: ['NON_SALES_CHANNEL']}},
    {id: 'view_shopify_plans_web', title: 'Plans', icon: Plans, flag: 'plan_selection', platform: {name: ['standalone']}},
  ]

  if (flag.analytics) {
    items.unshift({ id: 'analytics', title: 'Analytics', icon: StorefrontIcon })
    items.unshift({ id: 'stores', title: 'Home', flag: 'campaign_stores', icon: HomeIcon })
  } else {
    items.unshift({ id: 'stores', title: 'Home', flag: 'campaign_stores', icon: HomeIcon })
  }

  const filteredItems = items.filter(i => {
    const conditionsArray: boolean[] = []
    if (i.flag) {
      conditionsArray.push(flag[i.flag])
    }
    if (i?.platform) {
      conditionsArray.push(i?.platform.name.includes(platformName))
      i?.platform.type && !!appType && conditionsArray.push(i?.platform.type.includes(appType))
    }
    return conditionsArray.length > 0 ? conditionsArray.every(Boolean) : true
  })
  filteredItems.forEach(r => {r.href = getRoutePath(r.id)})
  return filteredItems
}

export const renderRoutes = (routes: SdxRoute[] = []): JSX.Element => (
  <Suspense fallback={<SdxLoading />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

// example usage
// instead of `history.push(`/path/to/store-settings`)` use `history.push(getRoutePath('store_settings'))`
export const getRoutePath = (id: RouteId, params?: {[key: string]: string}): string => {
  const route = getRoute(id)
  if (!route) return ''
  let path = route.path || ''
  if (params) {
    for (const key in params) {
      if (!path.includes(`:${key}`))
        console.warn(`getRoutePath: param ${key} not found in route ${id}`)
      else path = path.replace(`:${key}`, params[key])
    }
  }
  return path
}

export const getRoute = (id: RouteId): SdxRoute | undefined => {
  const find = recGetRoute(routes, id)
  if (!find) console.warn(`Route with id ${id} not found`)
  return find
}

const recGetRoute = ((routes: SdxRoute[], id: RouteId): SdxRoute | undefined => {
  for (const route of routes) {
    if (route.id === id) return route

    if (route.routes) {
      const found = recGetRoute(route.routes, id)
      if (found) return found
    }
  }
})

const appRoutePrefix = '/app'

const routes: SdxRoute[] = [
  {
    id: 'home',
    exact: true,
    path: '/home',
    component: lazy(() => import('src/views/index')),
  },
  {
    id: '/',
    exact: true,
    path: '/',
    component: lazy(() => import('src/views/index')),
  },
  {
    id: 'validate_plan',
    exact: true,
    path: '/validate-plan',
    component: lazy(() => import('src/views/ValidatePlan')),
  },
  {
    id: '404',
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    id: 'help',
    exact: true,
    path: '/help',
    // component: () => < href="https://support.simplicitydx.com" />,
  },
  {
    id: 'app',
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 'store_create',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/store/create`,
        component: lazy(() => import('src/views/CreateStorefront')),
      },
      {
        id: 'store_edit',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/store/edit/:id`,
        component: lazy(() => import('src/views/CreateStorefront')),
      },
      {
        id: 'stores',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/stores`,
        component: lazy(() => import('src/views/Storefronts')),
      },
      {
        id: 'store_analytics',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/store/analytics/:id`,
        component: lazy(() => import('src/views/CampaignAnalytics')),
      },
      {
        id: 'components',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/components`,
        component: lazy(() => import('src/views/Components')),
      },
      {
        id: 'component_create',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/component/create`,
        component: lazy(() => import('src/views/CreateComponent')),
      },
      {
        id: 'component_edit',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/component/edit/:id`,
        component: lazy(() => import('src/views/CreateComponent')),
      },
      {
        id: 'shopify_settings',
        exact: true,
        path: `${appRoutePrefix}/settings`,
        component: lazy(() => import('src/views/ShopifyEmbeddedApp/ShopifySettings')),
      },
      {
        id: 'page_settings',
        exact: true,
        guard: CampaignStoreGuard,
        path: `${appRoutePrefix}/pages/settings`,
        component: lazy(() => import('src/views/DefaultPagesSettings')),
      },
      {
        id: 'store_settings',
        exact: true,
        path: `${appRoutePrefix}/store-settings`,
        component: lazy(() => import('src/views/StoreSettings')),
      },
      {
        id: 'analytics',
        exact: true,
        path: `${appRoutePrefix}/analytics/home`,
        component: lazy(() => import('src/views/GeneralAnalytics')),
      },
      {
        id: 'shopify_plans',
        exact: true,
        path: `${appRoutePrefix}/plans`,
        component: lazy(() => import('src/views/ShopifyPlans')),
      },
      {
        id: 'view_shopify_plans',
        exact: true,
        path: `${appRoutePrefix}/view-plans`,
        component: lazy(() => import('src/views/ViewShopifyPlans')),
      },
      {
        id: 'view_shopify_plans_web',
        exact: true,
        path: `${appRoutePrefix}/view-plans-web`,
        component: lazy(() => import('src/views/ViewShopifyPlansWeb')),
      },
      {
        id: 'store_integrations',
        exact: true,
        path: `${appRoutePrefix}/store-integrations`,
        component: lazy(() => import('src/views/StoreIntegrations')),
      },
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
  {
    path: '/auth',
    guard: AuthGuard,
    layout: AuthLayout,
    routes: [
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
  {
    id: '*',
    path: '*',
    routes: [
      {
        component: () => <Redirect to='/home' />,
      },
    ],
  },
]

const recFlatten = list => {
  return list.reduce((a, b) => {
    if (b.routes) {
      return [...a, b, ...recFlatten(b.routes)]
    } else {
      return [...a, b]
    }
  }, [])
}

export default routes
