import React, { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { ComponentTypeResponse, getCategoryFields, getMinifiedFields } from 'src/views/Components/types'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
}

export const Storybook: FC<Props> = ({
  componentConfig,
  setComponentConfig,
}) => {
  const [numberError, setNumberError] = React.useState('')
  return (
    componentConfig.contentConfiguration.type === 'STOREFRONT_PAGE_PRIMITIVE' ?
      <div>
        <div style={{
          display: 'flex',
          marginRight: '12px',
        }}>
          <FormControl sx={{ marginTop: 1, marginRight: '12px', width: '100%' }}>
            <InputLabel id="image-fit-label">Image fit</InputLabel>
            <Select
              size='small' margin='dense'
              labelId="image-fit-label"
              id="image-fit"
              value={componentConfig.contentConfiguration.value.uiSettings.imageFit || 'contain'} // 'cover' | 'contain' | 'fill'
              label="Image fit"
              onChange={(event: SelectChangeEvent) => {
                const newComponent: ComponentTypeResponse = { ...componentConfig }
                if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return

                newComponent.contentConfiguration.value.uiSettings.imageFit = event.target.value as 'contain' | 'cover' | 'fill'
                setComponentConfig(newComponent)
              }}
            >
              <MenuItem value='contain'>Contain</MenuItem>
              <MenuItem value='cover'>Cover</MenuItem>
              <MenuItem value='fill'>Fill</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="storybook_field"
            style={{
              width: '100%',
              marginRight: '12px',
              marginBottom: '8px',
            }}
            select
            value={componentConfig.contentConfiguration.value.uiSettings.fields.length > 1 ? 'EXTENDED' : 'MINIFIED'}
            size='small' margin='dense' type='string' variant='outlined'
            label="Type"
            onChange={(e) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return
              newComponent.contentConfiguration.value.uiSettings.fields =
                e.target.value === 'MINIFIED' ? getMinifiedFields() : getCategoryFields()
              setComponentConfig(newComponent)
            }}
          >
            <MenuItem key={'MINIFIED'} value={'MINIFIED'}>Minified</MenuItem>
            <MenuItem key={'EXTENDED'} value={'EXTENDED'}>Extended</MenuItem>
          </TextField>
          <TextField
            id="storybook_view"
            style={{
              width: '100%',
              marginRight: '12px',
              marginBottom: '8px',
            }}
            onBlur={() => {
              setNumberError('')
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return
              if (newComponent.contentConfiguration.value.uiSettings.defaultRowCount === undefined) {
                newComponent.contentConfiguration.value.uiSettings.defaultRowCount = 1
                setComponentConfig(newComponent)
              }
            }}
            error={!!numberError}
            helperText={numberError && numberError}
            value={componentConfig.contentConfiguration.value.uiSettings.defaultRowCount}
            size='small' margin='dense' variant='outlined'
            label="Rows"
            onChange={(e) => {
              try {
                if (isNaN(Number(e.target.value))) throw new Error('Please enter a valid number')
                setNumberError('')
                const newComponent: ComponentTypeResponse = { ...componentConfig }
                if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return


                if (Number(e.target.value) < 1) {
                  newComponent.contentConfiguration.value.uiSettings.defaultRowCount = undefined
                  setComponentConfig(newComponent)
                  throw new Error('Minimum number of row is 1')
                }
                if (Number(e.target.value) > 15) {
                  newComponent.contentConfiguration.value.uiSettings.defaultRowCount = 15
                  setComponentConfig(newComponent)
                  throw new Error('Maximum number of row is 15')
                }
                newComponent.contentConfiguration.value.uiSettings.defaultRowCount = Number(e.target.value)
                setComponentConfig(newComponent)
              }
              catch (numberErr) {
                setNumberError(numberErr.message)
                return
              }
            }}
          />
        </div>
      </div>
      :
      <div></div>
  )
}
