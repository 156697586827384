import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from 'src/i18n/en.json'
import fr from 'src/i18n/fr.json'

const resources = {
  en,
  fr,
}
export const languageStorageKey = 'i18n_language'
// export const language = localStorage.getItem(languageStorageKey) || 'en'
export const language = 'en'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
    saveMissing: true, // must be enabled
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      console.warn({lng, ns, key, fallbackValue})
    },
  })

// const t = i18n.t.bind(i18n)
export default i18n
