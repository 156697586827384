import React, { useCallback, useEffect, useState, FC } from 'react'
import {Box, Button, IconButton, Switch, Tabs, useTheme } from '@mui/material'

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { ReactComponent as AddIcon } from 'src/assets/icons/Add.svg'

import { Ad, AdsResponse } from 'src/contexts/types'
import AdSelector from 'src/components/AdSelector'
import { IntegrationLocal, IntegrationsPayload } from 'src/views/StoreIntegrations/types'
import useGenericContext from 'src/hooks/useGenericContext'
import { logging } from 'src/utils/logging'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import { downloadURI } from './Header'
import useDebouncedState from 'src/hooks/useDebouncedState'

export type MetaDownloadModalProps = {
  startTimestamp: number
  endTimestamp: number
  isOpen: boolean
  toggleModal: () => void
}

const getInitialAdsResponse = () => ({
  offset: 0, ads: [], isEnd: false,
})

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  minHeight: '400px', // This makes the modal longer vertically
  maxHeight: '90vh',  // Ensure it doesn't overflow the viewport
  overflowY: 'auto',  // Adds scrolling if content exceeds the max height
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  opacity: 1,
  zIndex: 1300,  // Ensure the modal is above the backdrop
}

export const MetaDownloadModal: FC<MetaDownloadModalProps> = ({
  startTimestamp,
  endTimestamp,
  isOpen = false,
  toggleModal,
}) => {
  const theme = useTheme()
  const { getAds, getIntegrations, getMetaDownloadUrlV2 } = useGenericContext()
  const [integrations, setIntegrations] = useState<IntegrationLocal[]>([])
  const [integrationIndex, setIntegrationIndex] = useState(0)
  const [accountId, setAccountId] = useState('')

  const [adsResponse, setAdsResponse] = useState<AdsResponse & { offset: number, isEnd: boolean }>(getInitialAdsResponse())
  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebouncedState(searchString, 200)
  const [selectedAds, setSelectedAds] = useState<Ad[]>([])
  const [shouldSplitByDay, setShouldSplitByDay] = useState(false)

  // fetch our ad integrations
  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response: IntegrationsPayload | undefined = (await getIntegrations())?.data
        if (response) {
          let ints: IntegrationLocal[] = []

          response.forEach(x => {
            x.adAccounts.forEach((integration) => {
              ints.push({
                label: integration.name,
                externalId: integration.externalId + '',
                icon: AddIcon,
                type: 'meta-ads',
              })
            })
          })
          ints = ints.filter((x: any) => (x.type !== 'meta-ad') && (x.type !== 'meta_ads'))
          setIntegrations(ints)
        }
      } catch(err) {
        logging(err, { tags: { section: 'meta download modal integration getter' } })
      }
    }
    fetchIntegrations()
  }, [])

  // handle fetching ads
  useEffect(() => {
    getNextAds()
    // Changing offset will trigger a re-fetch
  }, [integrations, integrationIndex, debouncedSearchString])

  // get the selected ad account id
  useEffect(() => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    setAccountId(integration.externalId)
    setSelectedAds([])
  }, [integrations,integrationIndex])

  // ---> functions in callback
  const getNextAds = useCallback(async () => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    if (adsResponse.isEnd) return

    if (integration.type === 'meta-ads') {
      const response = await getAds(integration.externalId, 10, adsResponse.offset, debouncedSearchString)
      response?.data && setAdsResponse((prevAdsResponse) => {
        return {
          isEnd: response.data.ads.length < 10,
          offset: adsResponse.offset + response.data.ads.length,
          ads:  (debouncedSearchString && adsResponse.offset === 0) ? [...response.data.ads] : [
            ...prevAdsResponse.ads,
            ...response.data.ads,
          ],
        }
      })
    }
  }, [integrations, integrationIndex, adsResponse.isEnd, adsResponse.offset, getAds, debouncedSearchString])

  const handleSearchChange = React.useCallback((val: string) => {
    setSearchString(val)
    if (integrations[integrationIndex]?.type === 'meta-ads') {
      setAdsResponse(getInitialAdsResponse())
    }
  }, [integrationIndex, integrations])

  const dummy = ((val: string) =>{})

  const setIntegrationIndexFn = ((index) => {
    if (index === integrationIndex) return
    setIntegrationIndex(index)
  })

  const onSelect = ((ad) => {
    setSelectedAds([...selectedAds, ad])
  })

  const closeModal = () => {
    toggleModal()
    setSelectedAds([])
  }

  const getAndDownloadAdInsights = (async () => {
    const metaDownloadUrl = await getMetaDownloadUrlV2(startTimestamp, endTimestamp, selectedAds, accountId, shouldSplitByDay)
    metaDownloadUrl?.data.downloadUrl && downloadURI(metaDownloadUrl?.data.downloadUrl, 'meta.json')
  })

  const ads = adsResponse.ads

  if (!isOpen) return null
  return (
    <Box
      sx={style}
    >
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '18px',
          marginBottom: '12px',
          marginTop: '12px',
        }}
      >
            Select Ads For Insight Download
      </Box>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Tabs
          style={{
            flex: 2,
            position: 'relative',
          }}
          scrollButtons='auto'
          ScrollButtonComponent={({ direction, className, onClick, disabled }) => {
            return (
              <div
                onClick={onClick}
                className={className}
              >
                <Button
                  size='small'
                  style={{
                    minWidth: '40px',
                    color: disabled ? 'lightgrey' : 'inherit',
                  }}
                >
                  {direction === 'right' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                </Button>
              </div>
            )
          }}
          variant='scrollable'
          TabIndicatorProps={{ children: <span /> }}
          classes={{
            indicator: 'indicator',
            // flexContainer: {{ display: unset}},
          }}
          value={integrationIndex}
          indicatorColor='primary'
        >
          {
            integrations.map((integration, i) => {
              return (
                <Button
                  key={i}
                  onClick={() => setIntegrationIndexFn(i)}
                  color='inherit'
                  sx={{
                    minWidth: 'fit-content',
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                  }}>
                    <Box style={{
                      paddingRight: '6px',
                      paddingTop: '3px',
                    }}>
                      {typeof integration.icon !== 'undefined' &&
                        <integration.icon style={{
                          width: 20,
                          height: 20,
                        }} />
                      }
                    </Box>
                    <Box>{integration.label}</Box>
                  </Box>
                </Button>
              )
            })
          }
        </Tabs>
        <IconButton
          onClick={() => {
            closeModal()
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <Box>
          <AdSelector
            onRenderNext={getNextAds}
            onSelect={onSelect}
            items={ads}
            selected={undefined}
            campaignName={'campaignName'}
            onCampaignNameChange={dummy}
            campaignDescription={'campaignDescription'}
            onCampaignDescriptionChange={dummy}
            defaultOpen={false}
            handleSearchChange={handleSearchChange}
            searchString={searchString}
          />
        </Box>
      </div>
      <div>
        {
          selectedAds.length > 0 ?
            <Box
              sx={{
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: '8px',
                marginTop: '12px',
              }}
            >
            Selected Ad Names:
            </Box>: ''
        }
        {
          selectedAds.map((ad) => {
            return (
              <Box>{ad.adName}</Box>
            )
          })
        }
        <Box>
          <Switch
            style={{ color: shouldSplitByDay ? theme.palette.brand.urIndigo : '' }}
            checked={shouldSplitByDay}
            onChange={() => {
              setShouldSplitByDay(!shouldSplitByDay)
            }}
            color='primary'
          />
        Split By Day?
        </Box>
        {
          selectedAds.length >= 1 ?
            <Button
              style={{
                top: '4px',
              }}
              variant='outlined'
              onClick={() => {
                const handleOnClick = async () => {
                  await getAndDownloadAdInsights()
                  closeModal()
                }
                handleOnClick()
              }}
            >
          Submit Insights Request
            </Button> : ''
        }
      </div>
    </Box>
  )
}
