export enum LineItemFieldEnum {
  PRODUCT_NAME = 'PRODUCT_NAME',
  VARIANT_NAME = 'VARIANT_NAME',
  ITEMIZED_OPTIONS = 'ITEMIZED_OPTIONS',
  LOW_INVENTORY = 'LOW_INVENTORY'
}

export enum ImageFitEnum {
  CONTAIN = 'contain',
  COVER = 'cover',
  FILL = 'fill'
}

export type StorePrimitiveSettings = {
  lineItem: {
    uiSettings: {
      fields: LineItemFieldEnum[]
    }
  }
  product: {
    uiSettings: {
      imageFit: ImageFitEnum
      showImageSearch: boolean
    }
  }
  category: {
    uiSettings: {
      imageFit: ImageFitEnum
    }
  }
  storefront: {
    uiSettings: {
      imageFit: ImageFitEnum
    }
  }
}
