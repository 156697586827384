import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { Button, ListItem, Grid, Tooltip, Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import type { FC, ReactNode } from 'react'

import extractFirstTwoLettersOfUsername from 'src/utils/extractFirstTwoLettersOfUsername'
import { NavbarContext } from './index'

const PREFIX = 'NavItem'

const classes = {
  item: `${PREFIX}-item`,
  itemLeaf: `${PREFIX}-itemLeaf`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  buttonLabel: `${PREFIX}-buttonLabel`,
  buttonLabelOpen: `${PREFIX}-buttonLabelOpen`,
  expandedLabel: `${PREFIX}-expandedLabel`,
  alignCenter: `${PREFIX}-alignCenter`,
  forwardIcon: `${PREFIX}-forwardIcon`,
  userName: `${PREFIX}-userName`,
  content: `${PREFIX}-content`,
  bottomMenu: `${PREFIX}-bottomMenu`,
  active: `${PREFIX}-active`,
}

const StyledListItem = styled(ListItem)(({theme}) => ({
  [`& .${classes.item}`]: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`&.${classes.itemLeaf}`]: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'center',
    marginBottom: 16,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:hover .NavItem-content': {
      display: 'flex',
      width: 'auto',
      height: 'auto',
      opacity: 1,
      overflow: 'visible !important',
      transition: 'opacity 0.3s',
      transform: 'translate(calc(100% + 20px), -15%)',
    },
  },

  [`& .${classes.buttonLeaf}`]: {
    color: theme.palette.text.secondary,
    padding: '0px !important',
    width: '100%',
    minWidth: '36px',
    height: '36px',
    fontWeight: 'normal',
    '&:hover $title': {
      display: 'flex',
      width: 'auto',
      height: 'auto',
      opacity: 1,
      overflow: 'visible',
      transition: 'opacity 0.3s',
      transform: 'translate(calc(100% + 20px), -50%)',
    },
  },

  [`& .${classes.buttonLabel}`]: {
    justifyContent: 'center',
    marginLeft: '10px',
    marginRight: '10px',
  },

  [`& .${classes.buttonLabelOpen}`]: {
    justifyContent: 'flex-start',
  },

  [`& .${classes.expandedLabel}`]: {
    marginLeft: '10px',
    width: '120px',
  },

  [`& .${classes.alignCenter}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.forwardIcon}`]: {
    width: '12px',
    height: '12px',
    transform: 'translateY(1px)',
  },

  [`& .${classes.userName}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.brand.blueGrey,
    color: theme.palette.text.white,
    width: 30,
    height: 30,
    fontWeight: 'bold',
    fontSize: 16,
    // paddingLeft: 1,
    // paddingTop: 1,
  },

  [`& .${classes.content}`]: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    fontSize: 14,
    whiteSpace: 'nowrap',
    transform: '-100%, -50%)',
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.background.dark,
    boxShadow: theme.shadows[1],
    borderRadius: 5,
  },

  [`& .${classes.bottomMenu}`]: {
    top: 'unset',
    bottom: '-150%',
  },

  [`& .${classes.active}`]: {
    // color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.dark,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon path': {
      stroke: theme.palette.secondary.main,
    },
  },
}))

interface NavItemProps {
  children?: ReactNode
  className?: string
  depth: number
  hideTooltip?: boolean
  onClick?: any
  href?: string
  icon?: React.ComponentType<{ size?: string }>
  info?: React.ComponentType
  open?: boolean
  userName?: string
  content?: React.ComponentType
  title: string
  position?: 'top' | 'bottom'
  showArrow?: boolean
}

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  hideTooltip,
  onClick,
  href,
  icon: Icon,
  info: Info,
  open: openProp = false,
  content: Content,
  title,
  userName,
  position,
  showArrow,
  ...rest
}) => {

  const { menuExpanded } = useContext(NavbarContext)

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // if there is no title or content, then no menu item should be shown - blank entry
  if (!(title || Content)) {
    return null
  }

  const myIcon = Icon ? <Box
    style={{
      display: 'flex',
      width: '36px',
      height: '36px',
      placeContent: 'center',
      alignItems: 'center',
    }}>
    <Icon />
  </Box>
    : null

  return (
    <StyledListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Tooltip title={hideTooltip ? '' : title} placement="right">
        <Button
          component={href ? RouterLink : undefined }
          //Conditional NavLink stylings
          {...(href && { activeClassName: classes.active})}
          {...(href && { exact: true})}
          to={href ? href : undefined}
          data-testid={`navItem/${title}`}
          className={classes.buttonLeaf}
          style={{padding: paddingLeft}}
          // onClick overrides href
          { ...(onClick && { onClick: onClick as any })}
        >
          <Box style={{width: '100%'}} className={clsx(classes.buttonLabel, {[classes.buttonLabelOpen]: menuExpanded})}>
            {Icon && (menuExpanded ?
              <Box style={{textAlign: 'left', width: '100%'}}>
                <Box style={{
                  display: 'flex',
                  width: '100%',
                }}>
                  {myIcon}
                  <Box style={{
                    marginLeft: '10px',
                    width: '120px',
                    alignSelf: 'center',
                  }}>{title}</Box>
                  {showArrow ?
                    <Grid item className={classes.alignCenter} xs={1}>
                      <ArrowForwardIosIcon className={classes.forwardIcon} color="primary"/>
                    </Grid>
                    : null
                  }
                </Box>
              </Box>
              : myIcon
            )}

            {userName && (menuExpanded ?
              <Grid container justifyContent='space-between'>
                <Grid className={classes.alignCenter} item xs={11}>
                  <span className={classes.userName}>{extractFirstTwoLettersOfUsername(userName)}</span>
                  <span className={classes.expandedLabel}>{title}</span>
                </Grid>

                {showArrow ?
                  <Grid className={classes.alignCenter} item xs={1}>
                    <ArrowForwardIosIcon className={classes.forwardIcon} color="primary"/>
                  </Grid>
                  : null}
              </Grid>
              : <span className={classes.userName}>{extractFirstTwoLettersOfUsername(userName)}</span>
            )}

            {Content ? <span className={clsx(classes.content, {[classes.bottomMenu]: position === 'bottom'})}> <Content /> </span> : null}
            {Info && <Info />}
          </Box>
        </Button>
      </Tooltip>
    </StyledListItem>
  )
}



export default NavItem
