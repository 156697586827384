import React, { FC } from 'react'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { List, arrayMove } from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import ProductSelectorLarge, { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { ComponentTypeResponse } from 'src/views/Components/types'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedProductMap: Record<string, ProductItemFull>
  backgroundGradient: string
  openWhenNotSelected: boolean | undefined
}

export const ManualProductSelectionInput: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedProductMap,
  backgroundGradient,
  openWhenNotSelected,
}) => {
  const lastIndexId = 'lastIndexId'
  return (
    componentConfig.contentConfiguration.type === 'PRODUCT_PRIMITIVE' ?
      <Box>
        <FormControl sx={{ marginTop: 1, marginBottom: 1, width: '100%' }}>
          <InputLabel id="image-fit-label">Image fit</InputLabel>
          <Select
            size='small' margin='dense'
            labelId="image-fit-label"
            id="image-fit"
            value={componentConfig.contentConfiguration.value.uiSettings.imageFit || 'contain'} // 'cover' | 'contain' | 'fill'
            label="Image fit"
            onChange={(event: SelectChangeEvent) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

              newComponent.contentConfiguration.value.uiSettings.imageFit = event.target.value as 'contain' | 'cover' | 'fill'
              setComponentConfig(newComponent)
            }}
          >
            <MenuItem value='contain'>Contain</MenuItem>
            <MenuItem value='cover'>Cover</MenuItem>
            <MenuItem value='fill'>Fill</MenuItem>
          </Select>
        </FormControl>
        <List
          lockVertically
          values={componentConfig.contentConfiguration.value.apiSettings.productIds.concat([lastIndexId])}
          onChange={({ oldIndex, newIndex }) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

            newComponent.contentConfiguration.value.apiSettings.productIds = arrayMove(newComponent.contentConfiguration.value.apiSettings.productIds, oldIndex, newIndex)
            setComponentConfig(newComponent)
          }}
          renderList={(list) => <div {...list.props}>{list.children}</div>}
          renderItem={(listItem) => {
            const { isDragged } = listItem
            const prodId = listItem.value
            const j = listItem.index
            const isLast = listItem.value === lastIndexId
            return (
              <div
                {...listItem.props}
                key={isLast ? j : prodId}
              >
                <div style={{
                  paddingLeft: '20px',
                  background: backgroundGradient,
                }}>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        marginRight: '12px',
                      }}
                    >
                      <ProductSelectorLarge
                        openWhenNotSelected={openWhenNotSelected}
                        onSelect={(item) => {
                          if (!item) return
                          currentSelectedProductMap[item.productId] = item
                          const newComponent: ComponentTypeResponse = { ...componentConfig }
                          if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

                          if (isLast) {
                            newComponent.contentConfiguration.value.apiSettings.productIds = [...newComponent.contentConfiguration.value.apiSettings.productIds, item.productId]
                          }
                          else {
                            newComponent.contentConfiguration.value.apiSettings.productIds =
                              newComponent.contentConfiguration.value.apiSettings.productIds
                                .map(productId => productId === prodId ? item.productId : productId)
                          }
                          setComponentConfig(newComponent)
                        }}
                        selected={currentSelectedProductMap[prodId]}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        previouslySelected={componentConfig?.contentConfiguration?.value.apiSettings?.productIds}
                        label='Component Product'
                      />
                    </div>

                    <div
                      data-movable-handle
                      style={{
                        margin: '16px 6px 10px 0px',
                        cursor: isDragged ? 'grabbing' : 'grab',

                        opacity: isLast ? 0 : 1,
                        pointerEvents: isLast ? 'none' : 'auto',
                      }}
                    >
                      <DragIcon />
                    </div>

                    <IconButton
                      style={{
                        opacity: isLast ? 0 : 1,
                      }}
                      disabled={isLast}

                      onClick={() => {
                        const newComponent: ComponentTypeResponse = { ...componentConfig }
                        if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

                        newComponent.contentConfiguration.value.apiSettings.productIds = newComponent.contentConfiguration.value.apiSettings.productIds.filter(productId => productId !== prodId)
                        setComponentConfig(newComponent)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            )
          }}
        />
      </Box>
      :
      <div></div>
  )
}
