import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'

import type { FC, ReactNode } from 'react'
import type {
  GenericContextValue, ComponentsSearchFilter, StorefrontStatus, MerchantDetails,
} from './types'

import { saveStoreSettingsApi, getStoreSettingsApi, saveCustomDomainApi, getActiveCustomDomainApi, getThirdPartyApi, saveThirdPartyApi, getTrackingScriptsApi, saveTrackingScriptsApi, saveHeadTrackingScriptsApi, GetActiveCustomDomain, GetStoreSettings, GetThirdParty, GetTrackingScripts, SaveCustomDomain, SaveStoreSettings, SaveThirdParty, SaveTrackingScripts, SaveHeadTrackingScripts } from './api/settingsApi'
import { getProductsByIdsApi, getCategoriesApi, getPostApi, getPostsApi, uploadStorefrontImageAPI, searchProductsApi, searchCategoriesApi, GetCategories, GetPost, GetPosts, GetProductsByIds, SearchCategories, SearchProducts, UploadStorefrontImage } from './api/storefrontsApi'
import { getPerformanceAnalyticsApi, getCampaignAnalyticsApi, GetCampaignAnalytics, GetPerformanceAnalytics } from './api/campaignAnalyticsApi'
import { DuplicateStorefront, duplicateStorefrontApi, DuplicateStorefrontApi } from './api/duplicateStorefrontApi'
import { getCampaignTopChannelsApi, getTopCampaignsApi, getLatestOrdersApi, getTotalRevenueApi, getCampaignRevenueGraphApi, getCurrencyCodeApi, GetCampaignRevenueGraph, GetCampaignTopChannels, GetCurrencyCode, GetLatestOrders, GetTopCampaigns, GetTotalRevenue } from './api/homeAnalyticsApi'
import useAuth from 'src/hooks/useAuth'
import { GetIntegrations, GetMetaPixels, setMetaPixelApi, SaveCodeGetIntegrations, getIntegrationsApi, getMetaPixelsApi, saveCodeGetIntegrationsApi } from './api/integrationsApi'
import { logging } from 'src/utils/logging'
import { CreateDynamicPages, GetAllDefaultPages, createDynamicPagesAPI, getAllDefaultPagesAPI } from './api/dynamicPagesApi'
import { GetComponent, GetComponents, PublishComponent, getComponentApi, getComponentsApi, publishComponentApi } from './api/componentsApi'
import { ComponentTypeResponse } from 'src/views/Components/types'
import { PublishPageConfig, publishPageConfigApi } from './api/publishPageConfigApi'
import { GetAd, getAdApi } from './api/getAdApi'
import { GetAds, getAdsApi } from './api/getAdsApi'
import { PostLinkToMeta, postLinkToMetaApi } from './api/postLinkToMeta'
import { PageConfig } from 'src/types/interpretor'
import { GetPageConfigs, getPageConfigsApi } from './api/getPageConfigsApi'
import { GetAffectedPages, getAffectedPagesApi } from './api/getAffectedPages'
import { GetPageConfig, getPageConfigApi } from './api/getPageConfigApi'

import { GetTopCampaigns2, getTopCampaigns2Api } from './api/getTopCampaigns'
import { GetTopSocialChannels2, getTopSocialChannels2Api } from './api/getTopSocialChannels'
import { GetLatestOrders2, getLatestOrders2Api } from './api/getLatestOrders'
import { GetCampaignTickers, getCampaignTickerApi } from './api/getCampaignTickers'
import { GetCampaignSummaryMetrics, getCampaignSummaryMetricsApi } from './api/getCampaignSummaryMetrics'
import { GetCampaignAggregateDayOfWeek, getCampaignAggregateDayOfWeekApi } from './api/getCampaignAggregateDayOfWeek'
import { GetOptimalPage, getOptimalPageApi } from './api/getOptimalPage'
import { GetManualPage, getManualPageApi } from './api/getManualPage'
import { GetPageMetrics, getPageMetricsApi } from './api/getPageMetrics'
import { GetMetaDownloadUrl, getMetaDownloadApi } from './api/getMetaDownloadUrl'
import { GetMetaDownloadUrlV2, getMetaDownloadApiV2 } from './api/getMetaDownloadUrlV2'
import { GetMerchantDetails, getMerchantDetailsApi } from './api/getMerchantDetails'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import { GetSurveyExists, getSurveyExistsApi } from './api/getSurveyExists'
import { GetPermalinkConfig, GetPermalinkPreview, PostPermalinkConfig, getPermalinkConfigApi, getPermalinkPreviewApi, postPermalinkConfigApi } from './api/permalinkApi'
import { GetPageComponentMlMetrics, getPageComponentMlMetricsApi } from './api/getPageComponentMetrics'
import { GetStorePrimitiveSettings, getStorePrimitiveSettingsApi, SaveStorePrimitiveSettings, saveStorePrimitiveSettingsApi } from './api/storePrimitiveSettings'
import { StorePrimitiveSettings } from 'src/views/ComponentSettingsPage/types'

export type FetchStatusBasedPageConfigs = (newShowUnpublished?: boolean, loadMore?: boolean) => Promise<void>

interface GenericProviderProps {
  view: 'shopify' | 'web'
  shop: string
  host: string
  children: ReactNode
}

declare global {
  interface Window { __app: any }
}

const getInitialMerchantDetails = (): MerchantDetails => ({
  shop: 'sample',
  status: 'INACTIVE',
  plan: 'NONE',
  platform: 'SHOPIFY',
  createdAt: 1234,
  updatedAt: 1234,
})

export const GenericContext = createContext<GenericContextValue>({
  utmLinkBuilderPageId: '',
  setUtmLinkBuilderPageId: () => { },
  homeCustomDate: [0, 0],
  isBrand: false,
  setIsBrand: () => Promise.resolve(undefined),
  setHomeCustomDate: () => Promise.resolve(undefined),
  statusBasedPageConfigs: [],
  setStatusBasedPageConfigs: () => Promise.resolve(undefined),
  currencyCode: 'USD',
  loadingPages: true,
  setLoadingPages: () => { },
  loadingMore: true,
  getCurrencyCode: () => Promise.resolve(undefined),
  publishPageConfig: () => Promise.resolve(undefined),
  getProductsByIds: () => Promise.resolve(undefined),
  getPost: () => Promise.resolve(undefined),
  getPosts: () => Promise.resolve(undefined),
  getAd: () => Promise.resolve(undefined),
  getAds: () => Promise.resolve(undefined),
  postLinkToMeta: () => Promise.resolve(undefined),
  getPerformanceAnalytics: () => Promise.resolve(undefined),
  duplicateStorefront: () => Promise.resolve(undefined),
  getCampaignAnalytics: () => Promise.resolve(undefined),
  getCategories: () => Promise.resolve(undefined),
  getCampaignTopChannels: () => Promise.resolve(undefined),
  getTopCampaigns: () => Promise.resolve(undefined),
  getLatestOrders: () => Promise.resolve(undefined),
  getTotalRevenue: () => Promise.resolve(undefined),
  getCampaignRevenueGraph: () => Promise.resolve(undefined),
  shop: '',
  showUnpublished: false,
  setShowUnpublished: () => { },
  fetchStatusBasedPageConfigs: () => Promise.resolve(undefined),
  getPageConfig: () => Promise.resolve(undefined),
  saveStoreSettings: () => Promise.resolve(undefined),
  getStoreSettings: () => Promise.resolve(undefined),
  saveCustomDomain: () => Promise.resolve(undefined),
  getActiveCustomDomain: () => Promise.resolve(undefined),
  getThirdParty: () => Promise.resolve(undefined),
  saveThirdParty: () => Promise.resolve(undefined),
  getTrackingScripts: () => Promise.resolve(undefined),
  saveTrackingScripts: () => Promise.resolve(undefined),
  saveHeadTrackingScripts: () => Promise.resolve(undefined),
  getIntegrations: () => Promise.resolve(undefined),
  getMetaPixels: () => Promise.resolve(undefined),
  setMetaPixel: () => Promise.resolve(undefined),
  saveCodeGetIntegrations: () => Promise.resolve({ err: '', data: [] }),
  uploadStorefrontImage: () => Promise.resolve(undefined),
  getAffectedPages: () => Promise.resolve(undefined),
  getComponents: () => Promise.resolve(undefined),
  publishComponent: () => Promise.resolve(undefined),
  loadingComponents: true,
  refetchComponents: () => Promise.resolve(undefined),
  components: [],
  getComponent: () => Promise.resolve(undefined),
  getAllDefaultPages: () => Promise.resolve(undefined),
  createDynamicPages: () => Promise.resolve(undefined),
  searchProducts: () => Promise.resolve(undefined),
  searchCategories: () => Promise.resolve(undefined),
  merchantDetails: getInitialMerchantDetails(),
  getSurveyExists: () => Promise.resolve(undefined),
  saveStorePrimitiveSettings: () => Promise.resolve(undefined),
  storePrimitiveSettings: undefined,
  fetchStorePrimitiveSettings: () => Promise.resolve(undefined),

  getTopCampaigns2: () => Promise.resolve(undefined),
  getTopSocialChannels2: () => Promise.resolve(undefined),
  getLatestOrders2: () => Promise.resolve(undefined),
  getCampaignTickers: () => Promise.resolve(undefined),
  getCampaignSummaryMetrics: () => Promise.resolve(undefined),
  getCampaignAggregateDayOfWeek: () => Promise.resolve(undefined),
  getMetaDownloadUrl: () => Promise.resolve(undefined),
  getMetaDownloadUrlV2: () => Promise.resolve(undefined),

  getPageComponentMlMetrics: () => Promise.resolve(undefined),
  getOptimalPage: () => Promise.resolve(undefined),
  getManualPage: () => Promise.resolve(undefined),
  getPageMetrics: () => Promise.resolve(undefined),
  refreshMerchantDetails: () => Promise.resolve(undefined),
  getPermalinkConfig: () => Promise.resolve(undefined),
  postPermalinkConfig: () => Promise.resolve(undefined),
  getPermalinkPreview: () => Promise.resolve(undefined),
})

export const GenericProvider: FC<GenericProviderProps> = ({
  view,
  shop,
  host,
  children,
}) => {
  const [utmLinkBuilderPageId, setUtmLinkBuilderPageId] = useState<string>('')
  const [statusBasedPageConfigs, setStatusBasedPageConfigs] = useState<PageConfig[]>([])
  const [showUnpublished, setShowUnpublished] = useState(false)
  const [homeCustomDate, setHomeCustomDate] = useState([0, 0])
  const [isBrand, setIsBrand] = useState(false)
  const loadMoreOffset = useRef(0)
  const pageCfgsOffset = 0
  const defaultCfgsLimit = 20

  const [components, setComponents] = useState<ComponentTypeResponse[]>([])
  const [currencyCode, setCurrencyCode] = useState<string>('USD')
  const [loadingPages, setLoadingPages] = useState<boolean>(true)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [loadingComponents, setLoadingComponents] = useState<boolean>(true)
  const [loadingBaseInfo, setLoadingBaseInfo] = useState<boolean>(true)
  const [merchantDetails, setMerchantDetails] = useState<MerchantDetails>(getInitialMerchantDetails())
  const auth = useAuth()
  const activeShop = view === 'web' ? auth.selectedStore : shop
  const [storePrimitiveSettings, setStorePrimitiveSettings] = useState<StorePrimitiveSettings | undefined>(undefined)

  // TODO: solve injection differently, maybe with an injection object as first parameter?
  const passShop = useCallback((fn: any) => async (...args: any[]) => {
    try {
      return fn(activeShop, ...args)
    }
    catch (e) {
      console.warn('Error in passShopSession', e)
      logging(e, { tags: { section: 'generic context' } })
    }
  }, [activeShop])

  const passShopCurrency = useCallback((fn: any) => async (...args: any[]) => {
    try {
      return fn(activeShop, currencyCode, ...args)
    }
    catch (e) {
      console.warn('Error in passShopSession', e)
      logging(e, { tags: { section: 'generic context' } })
    }
  }, [activeShop, currencyCode])

  // NOTE: check if the dependency is correct, passShopSession or passShopSessionCurrency
  const getPageConfigs: GetPageConfigs = useCallback(passShop(getPageConfigsApi), [passShop])
  const getPageConfig: GetPageConfig = useCallback(passShop(getPageConfigApi), [passShop])
  const getAffectedPages: GetAffectedPages = useCallback(passShop(getAffectedPagesApi), [passShop])
  const getComponents: GetComponents = useCallback(passShop(getComponentsApi), [passShop])
  const publishComponent: PublishComponent = useCallback(passShop(publishComponentApi), [passShop])
  const publishPageConfig: PublishPageConfig = useCallback(passShop(publishPageConfigApi), [passShop])
  const getProductsByIds: GetProductsByIds = useCallback(passShop(getProductsByIdsApi), [passShop])
  const getPost: GetPost = useCallback(passShop(getPostApi), [passShop])
  const getPosts: GetPosts = useCallback(passShop(getPostsApi), [passShop])
  const getAd: GetAd = useCallback(passShop(getAdApi), [passShop])
  const getAds: GetAds = useCallback(passShop(getAdsApi), [passShop])
  const postLinkToMeta: PostLinkToMeta = useCallback(passShop(postLinkToMetaApi), [passShop])
  const getCategories: GetCategories = useCallback(passShop(getCategoriesApi), [passShop])
  const getPerformanceAnalytics: GetPerformanceAnalytics = useCallback(passShopCurrency(getPerformanceAnalyticsApi), [passShopCurrency])
  const duplicateStorefront: DuplicateStorefront = useCallback(passShop(duplicateStorefrontApi), [passShop])
  const getCampaignAnalytics: GetCampaignAnalytics = useCallback(passShopCurrency(getCampaignAnalyticsApi), [passShopCurrency])
  const getCampaignTopChannels: GetCampaignTopChannels = useCallback(passShopCurrency(getCampaignTopChannelsApi), [passShopCurrency])
  const getTopCampaigns: GetTopCampaigns = useCallback(passShopCurrency(getTopCampaignsApi), [passShopCurrency])
  const getLatestOrders: GetLatestOrders = useCallback(passShopCurrency(getLatestOrdersApi), [passShopCurrency])
  const getTotalRevenue: GetTotalRevenue = useCallback(passShopCurrency(getTotalRevenueApi), [passShopCurrency])
  const getCampaignRevenueGraph: GetCampaignRevenueGraph = useCallback(passShopCurrency(getCampaignRevenueGraphApi), [passShopCurrency])
  const saveStoreSettings: SaveStoreSettings = useCallback(passShop(saveStoreSettingsApi), [passShop])
  const getStoreSettings: GetStoreSettings = useCallback(passShop(getStoreSettingsApi), [passShop])
  const saveCustomDomain: SaveCustomDomain = useCallback(passShop(saveCustomDomainApi), [passShop])
  const getActiveCustomDomain: GetActiveCustomDomain = useCallback(passShop(getActiveCustomDomainApi), [passShop])
  const getThirdParty: GetThirdParty = useCallback(passShop(getThirdPartyApi), [passShop])
  const saveThirdParty: SaveThirdParty = useCallback(passShop(saveThirdPartyApi), [passShop])
  const getTrackingScripts: GetTrackingScripts = useCallback(passShop(getTrackingScriptsApi), [passShop])
  const saveTrackingScripts: SaveTrackingScripts = useCallback(passShop(saveTrackingScriptsApi), [passShop])
  const saveHeadTrackingScripts: SaveHeadTrackingScripts = useCallback(passShop(saveHeadTrackingScriptsApi), [passShop])
  const getCurrencyCode: GetCurrencyCode = useCallback(passShop(getCurrencyCodeApi), [passShop])
  const getIntegrations: GetIntegrations = useCallback(passShop(getIntegrationsApi), [passShop])
  const getMetaPixels: GetMetaPixels = useCallback(passShop(getMetaPixelsApi), [passShop])
  const setMetaPixel: GetMetaPixels = useCallback(passShop(setMetaPixelApi), [passShop])
  const saveTokenGetIntegrations: SaveCodeGetIntegrations = useCallback(passShop(saveCodeGetIntegrationsApi), [passShop])
  const uploadStorefrontImage: UploadStorefrontImage = useCallback(passShop(uploadStorefrontImageAPI), [passShop])
  const getComponent: GetComponent = useCallback(passShop(getComponentApi), [passShop])
  const getAllDefaultPages: GetAllDefaultPages = useCallback(passShop(getAllDefaultPagesAPI), [passShop])
  const createDynamicPages: CreateDynamicPages = useCallback(passShop(createDynamicPagesAPI), [passShop])
  const searchProducts: SearchProducts = useCallback(passShop(searchProductsApi), [passShop])
  const searchCategories: SearchCategories = useCallback(passShop(searchCategoriesApi), [passShop])
  const getOptimalPage: GetOptimalPage = useCallback(passShop(getOptimalPageApi), [passShop])
  const getManualPage: GetManualPage = useCallback(passShop(getManualPageApi), [passShop])
  const getPageMetrics: GetPageMetrics = useCallback(passShop(getPageMetricsApi), [passShop])
  const getMetaDownloadUrl: GetMetaDownloadUrl = useCallback(passShopCurrency(getMetaDownloadApi), [passShop])
  const getMetaDownloadUrlV2: GetMetaDownloadUrlV2 = useCallback(passShop(getMetaDownloadApiV2), [passShop])
  const getSurveyExists: GetSurveyExists = useCallback(passShop(getSurveyExistsApi), [passShop])
  const getStorePrimitiveSettings: GetStorePrimitiveSettings = useCallback(passShop(getStorePrimitiveSettingsApi), [passShop])
  const saveStorePrimitiveSettings: SaveStorePrimitiveSettings = useCallback(passShop(saveStorePrimitiveSettingsApi), [passShop])
  const getPageComponentMlMetrics: GetPageComponentMlMetrics = useCallback(passShop(getPageComponentMlMetricsApi), [passShop])

  const getTopCampaigns2: GetTopCampaigns2 = useCallback(passShopCurrency(getTopCampaigns2Api), [passShopCurrency])
  const getTopSocialChannels2: GetTopSocialChannels2 = useCallback(passShopCurrency(getTopSocialChannels2Api), [passShopCurrency])
  const getLatestOrders2: GetLatestOrders2 = useCallback(passShopCurrency(getLatestOrders2Api), [passShopCurrency])
  const getCampaignTickers: GetCampaignTickers = useCallback(passShopCurrency(getCampaignTickerApi), [passShopCurrency])
  const getCampaignSummaryMetrics: GetCampaignSummaryMetrics = useCallback(passShopCurrency(getCampaignSummaryMetricsApi), [passShopCurrency])
  const getCampaignAggregateDayOfWeek: GetCampaignAggregateDayOfWeek = useCallback(passShopCurrency(getCampaignAggregateDayOfWeekApi), [passShopCurrency])
  const getMerchantDetails: GetMerchantDetails = useCallback(passShop(getMerchantDetailsApi), [passShop])
  const getPermalinkConfig: GetPermalinkConfig = useCallback(passShop(getPermalinkConfigApi), [passShop])
  const postPermalinkConfig: PostPermalinkConfig = useCallback(passShop(postPermalinkConfigApi), [passShop])
  const getPermalinkPreview: GetPermalinkPreview = useCallback(passShop(getPermalinkPreviewApi), [passShop])

  useEffect(() => {
    // toggling resets the list
    setStatusBasedPageConfigs([])
  }, [showUnpublished])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getStorePrimitiveSettings()
      if (response && typeof response.data !== 'undefined') {
        setStorePrimitiveSettings(response.data)
      }
    }
    fetchData()
  }, [])

  const fetchStorePrimitiveSettings = (async () => {
    const response = await getStorePrimitiveSettings()
    if (response && typeof response.data !== 'undefined') {
      setStorePrimitiveSettings(response.data)
      return response.data
    } else return undefined
  })

  const fetchStatusBasedPageConfigs: FetchStatusBasedPageConfigs = useCallback(async (newShowUnpublished?: boolean, loadMore?: boolean) => {
    if (loadMore) setLoadingMore(true)
    else setLoadingPages(true)

    // NOTE: since setStatusBasedPageConfigs is async and is using setState callback,
    //  by the time it gets called its value will be cleaned by the useEffect above
    // TODO: a bigger issue is that we are lacking a way to change a callback dependency in the same frame
    //  so we pass new values as parameters, in this case the newShowUnpublished
    if (newShowUnpublished !== undefined) setShowUnpublished(newShowUnpublished)

    const status = (newShowUnpublished === undefined ? showUnpublished : newShowUnpublished) ? 'UNPUBLISHED' : 'PUBLISHED'

    try {
      if (loadMore) loadMoreOffset.current = defaultCfgsLimit + loadMoreOffset.current
      else loadMoreOffset.current = 0
      const cfgs = await getPageConfigs(status, { limit: defaultCfgsLimit, offset: loadMore ? loadMoreOffset.current : pageCfgsOffset })

      if (loadMore) setStatusBasedPageConfigs(prevState => [...prevState, ...cfgs])
      else setStatusBasedPageConfigs(cfgs)

      if (cfgs.length === defaultCfgsLimit) setLoadingMore(false)
    }
    catch (e) {
      setStatusBasedPageConfigs([])
      console.error('Error getPageConfigs', e)
      logging(e, { tags: { section: 'getPageConfigs' } })
    }
    finally {
      setLoadingPages(false)
    }
  }, [showUnpublished, getPageConfigs])

  useEffect(() => {
    // Techdebt: this is a temporary solution, we should use the flag ideally, but useFlag is not available in the context yet. If we need flag again here, we will fix.
    if (merchantDetails.plan !== 'ENTERPRISE') return setLoadingPages(false)
    fetchStatusBasedPageConfigs()
  }, [activeShop, showUnpublished, fetchStatusBasedPageConfigs, merchantDetails.plan])

  useEffect(() => {
    // Techdebt: this is a temporary solution, we should use the flag ideally, but useFlag is not available in the context yet. If we need flag again here, we will fix.
    if (merchantDetails.plan !== 'ENTERPRISE') return setLoadingComponents(false)
    async function fetchComponents() {
      setLoadingComponents(true)
      try {
        const response = await getComponents()
        setComponents(response?.data || [])
        setLoadingComponents(false)
      } catch (e) {
        setComponents([])
        setLoadingComponents(false)
        console.warn('Error getComponents', e)
        logging(e, { tags: { section: 'generic context' } })
      }
    }

    fetchComponents()
  }, [activeShop, getComponents, merchantDetails.plan])

  const refetchComponents = async (searchFilter: ComponentsSearchFilter) => {
    const response = await getComponents(searchFilter)
    setComponents(response?.data || [])
  }

  const refreshMerchantDetails = async () => {
    try {
      const merchantDetailsResponse = await getMerchantDetails()
      merchantDetailsResponse?.data && setMerchantDetails(merchantDetailsResponse?.data)
    } catch (e) {
      console.warn('Error fetching merchant details', e)
      logging(e, { tags: { section: 'Error fetching merchant details' } })
    }
  }

  useEffect(() => {
    if (merchantDetails?.plan === 'BASE') {
      setIsBrand(true)
    }
  }, [merchantDetails?.plan])

  useEffect(() => {
    setLoadingBaseInfo(true)
    async function fetchCurrencyCode() {
      try {
        const response = await getCurrencyCode()
        response?.data && setCurrencyCode(response?.data?.defaultCurrencyCode)
      } catch (e) {
        console.warn('Error getCurrencyCode', e)
        logging(e, { tags: { section: 'generic context currency code' } })
      }

      try {
        const merchantDetailsResponse = await getMerchantDetails()
        merchantDetailsResponse?.data && setMerchantDetails(merchantDetailsResponse?.data)
        setLoadingBaseInfo(false)
      } catch (e) {
        setLoadingBaseInfo(false)
        console.warn('Error getCurrencyCode', e)
        logging(e, { tags: { section: 'generic context merchant details' } })
      }

    }
    fetchCurrencyCode()
  }, [activeShop, getCurrencyCode, getMerchantDetails])

  if (loadingBaseInfo) {
    return <SdxLoading />
  }

  return (
    <GenericContext.Provider
      value={{
        utmLinkBuilderPageId,
        setUtmLinkBuilderPageId,
        homeCustomDate,
        setHomeCustomDate,
        isBrand,
        setIsBrand,
        statusBasedPageConfigs,
        setStatusBasedPageConfigs,
        components,
        currencyCode,
        getCurrencyCode,
        loadingPages,
        setLoadingPages,
        loadingMore,
        loadingComponents,
        publishPageConfig,
        getProductsByIds,
        getPost,
        getPosts,
        getAd,
        getAds,
        postLinkToMeta,
        getCategories,
        getPerformanceAnalytics,
        duplicateStorefront,
        getCampaignAnalytics,
        getCampaignTopChannels,
        getTopCampaigns,
        getLatestOrders,
        getTotalRevenue,
        getCampaignRevenueGraph,
        shop: activeShop,
        fetchStatusBasedPageConfigs,
        getPageConfig,
        refetchComponents,
        showUnpublished,
        setShowUnpublished,
        saveStoreSettings,
        getStoreSettings,
        saveCustomDomain,
        getActiveCustomDomain,
        getThirdParty,
        saveThirdParty,
        getTrackingScripts,
        saveTrackingScripts,
        saveHeadTrackingScripts,
        getIntegrations,
        getMetaPixels,
        setMetaPixel,
        saveCodeGetIntegrations: saveTokenGetIntegrations,
        uploadStorefrontImage,
        getAffectedPages,
        getComponents,
        getComponent,
        publishComponent,
        getAllDefaultPages,
        createDynamicPages,
        searchProducts,
        searchCategories,
        merchantDetails,
        refreshMerchantDetails,
        saveStorePrimitiveSettings,
        storePrimitiveSettings,
        fetchStorePrimitiveSettings,

        getTopCampaigns2,
        getTopSocialChannels2,
        getLatestOrders2,

        getCampaignTickers,
        getCampaignSummaryMetrics,
        getCampaignAggregateDayOfWeek,
        getMetaDownloadUrl,
        getMetaDownloadUrlV2,
        getSurveyExists,

        getOptimalPage,
        getManualPage,
        getPageMetrics,
        getPageComponentMlMetrics,

        getPermalinkConfig,
        postPermalinkConfig,
        getPermalinkPreview,
      }}
    >
      {children}
    </GenericContext.Provider>
  )
}

export const GenericConsumer = GenericContext.Consumer

export default GenericContext
