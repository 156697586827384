import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Button, ListItem, Grid,   Tooltip } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'


import type { FC, ReactNode } from 'react'

import extractFirstTwoLettersOfUsername from 'src/utils/extractFirstTwoLettersOfUsername'
import { NavbarContext } from './index'

const PREFIX = 'NavItem'

const classes = {
  itemLeaf: `${PREFIX}-itemLeaf`,
  buttonLabel: `${PREFIX}-buttonLabel`,
  buttonLabelOpen: `${PREFIX}-buttonLabelOpen`,
  expandedLabel: `${PREFIX}-expandedLabel`,
  alignCenter: `${PREFIX}-alignCenter`,
  iconSize: `${PREFIX}-iconSize`,
  userName: `${PREFIX}-userName`,
  content: `${PREFIX}-content`,
  bottomMenu: `${PREFIX}-bottomMenu`,
}

const StyledListItem = styled(ListItem)((
  {
    theme,
  },
) => ({
  [`&.${classes.itemLeaf}`]: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:hover $content': {
      display: 'flex',
      width: 'auto',
      height: 'auto',
      opacity: 1,
      overflow: 'visible !important',
      transition: 'opacity 0.3s',
      transform: 'translate(calc(100% + 20px), -15%)',
    },
  },

  [`& .${classes.buttonLabel}`]: {
    justifyContent: 'center',
    marginLeft: '10px',
    marginRight: '10px',
  },

  [`& .${classes.buttonLabelOpen}`]: {
    justifyContent: 'flex-start',
  },

  [`& .${classes.expandedLabel}`]: {
    marginLeft: '10px',
  },

  [`& .${classes.alignCenter}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.iconSize}`]: {
    width: '12px',
    height: '12px',
  },

  [`& .${classes.userName}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.brand.blueGrey,
    color: theme.palette.text.white,
    width: 30,
    height: 30,
    fontWeight: 'bold',
    fontSize: 16,
  },

  [`& .${classes.content}`]: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    fontSize: 14,
    whiteSpace: 'nowrap',
    transform: '-100%, -50%)',
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.background.dark,
    boxShadow: theme.shadows[1],
    borderRadius: 5,
  },

  [`& .${classes.bottomMenu}`]: {
    top: '90px',
    right: '330px',
  },
}))

interface NavItemProps {
  children?: ReactNode
  className?: string
  depth: number
  hideTooltip?: boolean
  href?: string
  userName?: string
  content?: any
  title: string
  position?: 'right'
  showArrow?: boolean
}

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  hideTooltip,
  href,
  content: Content,
  title,
  userName,
  position,
  showArrow,
  ...rest
}) => {

  const { menuExpanded } = useContext(NavbarContext)

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = { paddingLeft }

  // if there is no title or content, then no menu item should be shown - blank entry
  if (!(title || Content)) {
    return null
  }

  return (
    <StyledListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Tooltip title={hideTooltip ? '' : title} placement="right">
        <Button
          data-testid={`navItem/${title}`}
          classes={{
            // label: clsx(classes.buttonLabel, {[classes.buttonLabelOpen]: menuExpanded}),
          }}
          style={style}
        >
          {userName && ( menuExpanded ? <Grid container justifyContent='space-between'><Grid className={classes.alignCenter} item xs={11}><span className={classes.userName}>{extractFirstTwoLettersOfUsername(userName )}</span> <span className={classes.expandedLabel}>{title}</span> </Grid>{showArrow ? <Grid className={classes.alignCenter} item xs={1}><ArrowForwardIosIcon className={classes.iconSize} color="primary"/></Grid> : null}</Grid> : <span className={classes.userName}>{extractFirstTwoLettersOfUsername(userName )}</span>
          )}
          {Content ? <span className={clsx(classes.content, {[classes.bottomMenu]: position === 'right'})}> <Content /> </span> : null}
        </Button>
      </Tooltip>
    </StyledListItem>
  )
}



export default NavItem
