import React, {FC} from 'react'
import {Box, Button, IconButton, TextField, MenuItem, Select, FormControlLabel, Switch} from '@mui/material'
import {arrayMove, List} from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import {StorePrimitiveSettings, LineItemFieldEnum, ImageFitEnum} from './types'
import {ImageFitSelector} from './ImageFitSelector'


export const ComponentSettingsUI: FC<{
  primitiveSettings: StorePrimitiveSettings
  setPrimitiveSettings: (primitiveSettings: StorePrimitiveSettings) => void
}> = ({primitiveSettings, setPrimitiveSettings}) => {

  const handleCartLineItemFieldOrderChange = ((oldIndex: number, newIndex: number) =>{
    const newPrimitiveSettings = Object.assign({}, primitiveSettings)
    const newFields = [...primitiveSettings.lineItem.uiSettings.fields]
    newPrimitiveSettings.lineItem.uiSettings.fields = arrayMove(newFields, oldIndex, newIndex)
    setPrimitiveSettings({ ...newPrimitiveSettings })
  })

  const handleCartLineItemFieldRemove = ((index: number) => {
    const newPrimitiveSettings = Object.assign({}, primitiveSettings)
    const newFields = [...primitiveSettings.lineItem.uiSettings.fields]
    newFields.splice(index, 1)
    newPrimitiveSettings.lineItem.uiSettings.fields = newFields
    setPrimitiveSettings({ ...newPrimitiveSettings })
  })

  const handleCartLineItemFieldAdd = ((field: LineItemFieldEnum) => {
    const newPrimitiveSettings = Object.assign({}, primitiveSettings)
    newPrimitiveSettings.lineItem.uiSettings.fields = [...newPrimitiveSettings.lineItem.uiSettings.fields, field]
    setPrimitiveSettings({ ...newPrimitiveSettings })
  })

  const availableCartLineItemFields = Object.values(LineItemFieldEnum).filter(field => !primitiveSettings.lineItem.uiSettings.fields.includes(field))

  const handleImageFitChange = (section: 'storefront' | 'product' | 'category', imageFitValue: ImageFitEnum) => {
    const newSettings = { ...primitiveSettings }
    newSettings[section].uiSettings.imageFit = imageFitValue
    setPrimitiveSettings(newSettings)
  }

  const handleShowImageSearchToggle = (shouldShow: boolean) => {
    const newSettings = { ...primitiveSettings }
    newSettings.product.uiSettings.showImageSearch = shouldShow
    setPrimitiveSettings(newSettings)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            mb: 2,
          }}
        >
          Component Settings
        </Box>
        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            mb: 2,
          }}
        >
          Cart Line Items
        </Box>
        <Box>
          Line Item Field Configuration
        </Box>
        <Box sx={{
          maxHeight: '210px',
          border: '1px solid lightgray',
          borderRadius: '5px',
          overflow: 'auto',
          pt: 2,
          mb: 2,
          pr: 0.5,
        }}>
          <List
            lockVertically
            values={primitiveSettings.lineItem.uiSettings.fields || []}
            onChange={({ oldIndex, newIndex }) => {
              handleCartLineItemFieldOrderChange(oldIndex, newIndex)
            }}
            renderList={(list) => <Box {...list.props}>{list.children}</Box>}
            renderItem={(listItem) => {
              const value = listItem.value
              const index = listItem.index ? listItem.index : primitiveSettings.lineItem.uiSettings.fields.findIndex((val) => val === value)
              return (
                <Box
                  {...listItem.props}
                  key={value}
                >
                  <Box sx={{
                    ml: 1,
                  }}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '6px',
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          mb: 2,
                        }}
                      >
                        <TextField
                          variant='outlined'
                          size='small'
                          fullWidth
                          label='Line Item Field'
                          value={value}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Box>

                      <Box sx={{display: 'flex', mt: 1, alignItems: 'baseline'}}>

                        <Box
                          data-movable-handle
                          sx={{
                            mr: 1,
                            ml: 1,
                            cursor: listItem.isDragged ? 'grabbing' : 'grab',
                          }}
                        >
                          <DragIcon />
                        </Box>

                        <IconButton
                          sx={{
                            transform: 'translateY(-5px)',
                          }}

                          onClick={() => {
                            handleCartLineItemFieldRemove(index)
                          }}
                          disabled={value === LineItemFieldEnum.PRODUCT_NAME} // Disable if PRODUCT_NAME
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" sx={{ pb: 1 }}>
          <Select
            value=""
            displayEmpty
            onChange={(event) => {
              handleCartLineItemFieldAdd(event.target.value as LineItemFieldEnum)
            }}
          >
            <MenuItem value="" disabled>
              Add Line Item Field
            </MenuItem>
            {availableCartLineItemFields.map((field) => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            mb: 2,
            mt: 2,
          }}
        >
          Product Components
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={primitiveSettings.product.uiSettings.showImageSearch || false}
              onChange={(event) => {
                handleShowImageSearchToggle(event.target.checked)
              }}
            />
          }
          label="Show Image Search"
          sx={{ marginBottom: '16px' }}
        />
        <ImageFitSelector
          label="Product"
          imageFitValue={primitiveSettings.product.uiSettings.imageFit || 'Product'}
          onImageFitChange={(value) => handleImageFitChange('product', value)}
          imageFitDisplay={primitiveSettings.product.uiSettings.imageFit}
        />

        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            mb: 2,
            mt: 2,
          }}
        >
          Category Components
        </Box>
        <ImageFitSelector
          label="Category"
          imageFitValue={primitiveSettings.category.uiSettings.imageFit || 'contain'}
          onImageFitChange={(value) => handleImageFitChange('category', value)}
          imageFitDisplay={primitiveSettings.category.uiSettings.imageFit}
        />

        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            mb: 2,
            mt: 2,
          }}
        >
          Storefront Components
        </Box>
        <ImageFitSelector
          label="Storefront"
          imageFitValue={primitiveSettings.storefront.uiSettings.imageFit || 'contain'}
          onImageFitChange={(value) => handleImageFitChange('storefront', value)}
          imageFitDisplay={primitiveSettings.storefront.uiSettings.imageFit}
        />
      </Box>
    </Box>
  )
}
