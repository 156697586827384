import { PageConfig } from 'src/types/interpretor'

export const temporaryUtm = {
  utmCampaign: '_temporary_utm_campaign',
  utmSource: '_temporary_utm_source',
  utmMedium: '_temporary_utm_medium',
}

export const getInitialPageConfig = (): PageConfig => {
  const now = new Date().getTime()
  return ({
    id: '',
    internalName: '',
    headline: '',
    subheadline: '',
    layout: 'SINGLE_PRODUCT',

    createdAt: now,
    updatedAt: now,
    startDate: now,

    redirectUrl: '',
    pageUrl: '',
    pageUrlWithUtms: '',

    // TODO: this is temporary, remove restriction on backend
    ...temporaryUtm,

    components: [],
    componentIds: [],
    mlOrderingUsed: [],
    pageComponentOverrides: {},
    componentOrderId: '',

    products: [],
    categories: {
      uiSettings: {
        fields: [],
      },
      apiSettings: {
        categoryIds: [],
      },

    },
    campaignMedias: [],
  })
}
