import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Resizable } from 'react-resizable'
import type { FC } from 'react'
import { Paper, Button, Box } from '@mui/material'

import { deepCloneJson } from 'src/utils/helpers'
import useGenericContext from 'src/hooks/useGenericContext'
import { ComponentTypeResponse } from '../Components/types'
import { ExitAttributionUI } from './ExitAttributionUI'
import { getRoutePath } from 'src/routes'
import { useFlags } from 'launchdarkly-react-client-sdk'

const EditExitAttribution: FC = () => {
  const history = useHistory()
  const { publishComponent, getComponent, refetchComponents } = useGenericContext()

  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  // TODO: how should we handle selectedStore changes?
  const [originalComponentConfig, setOriginalComponentConfig] = useState<ComponentTypeResponse>()
  const [componentConfig, setComponentConfig] = useState<ComponentTypeResponse | undefined>(undefined)

  const searchParams = useParams() as any

  const [hasDiff, setHasDiff] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [resizeState, setResizeState] = useState({ width: 400, height: 711 })
  const [componentId, setComponentId] = useState('')

  const { analytics } = useFlags()

  const onResize = (event, { element, size, handle }) => {
    setResizeState({ width: size.width, height: size.height })
  }

  const isLocalhostIframeName = 'isLocalhostIframe'
  const [isLocalhost, setIsLocalhost] = useState(window.location.hostname === 'localhost')
  const [isLocalhostIframe, setIsLocalhostIframe] = useState(localStorage.getItem(isLocalhostIframeName) === 'true')

  const handleSetIsLocalhostIframe = ((val: boolean) => {
    setIframeLoaded(false)
    setIsLocalhostIframe(val)
    localStorage.setItem(isLocalhostIframeName, val ? 'true' : '')
  })

  useEffect(() => {
    const setComponentSettings = async () => {
      const response = await getComponent('', 'ATTRIBUTION_SURVEY')
      if (!(response?.data)) return
      const componentSettings = response.data[0]
      setComponentId(componentSettings.componentId)
      setOriginalComponentConfig(deepCloneJson(componentSettings))
      setComponentConfig(deepCloneJson(componentSettings))
    }
    setComponentSettings()
  }, [getComponent, searchParams])

  useEffect(() => {
    if (originalComponentConfig && componentConfig) {
      // TODO: make a helper function that compares the objects
      let newHasDiff = false

      newHasDiff = newHasDiff ||
        originalComponentConfig.status !== componentConfig.status

      newHasDiff = newHasDiff ||
        (JSON.stringify(originalComponentConfig) !== JSON.stringify(componentConfig))

      setHasDiff(newHasDiff)
    }
  }, [originalComponentConfig, componentConfig])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (componentConfig) {
        // Our FE api settings check if ID is present, if not it creates a new component by calling a different endpoint
        const response = await publishComponent(componentConfig, componentId)

        if (response?.status === 200) {
          await refetchComponents()
          setLoading(false)
        } else {
          window.alert('Error creating/updating component')
          console.error('Error creating/updating component')
          setLoading(false)
        }
      } else {
        window.alert('Error creating/updating component - no config to send')
        console.error('Error creating/updating component - no config to send')
        setLoading(false)
      }
    }
    catch (error) {
      console.error('Error creating/updating component', error)
      window.alert('Error creating/updating component')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!componentConfig) return
    if (!iframeRef.current) return
    if (!iframeLoaded) return

    const payload = {
      type: 'previewConfig',
      value: {
        config: componentConfig,
        region: process.env.REACT_APP_DEPLOY_REGION,
      },
    }
    const iframeWindow = iframeRef.current?.contentWindow
    iframeWindow?.postMessage(JSON.stringify(payload), '*')
  }, [componentConfig, iframeRef, iframeLoaded, isLocalhostIframe])

  const iframeSrc = isLocalhost && isLocalhostIframe ?
    `http://localhost:3030`
    : `https://tag.ur2inc.com/survey-widget/survey-preview${process.env.REACT_APP_DEPLOY_REGION === 'dev-use2' ? '-dev' : ''}${process.env.REACT_APP_DEPLOY_REGION === 'stage-use2' ? '-stage' : ''}.html`

  if (!componentConfig || !analytics) return <span />

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        height: `calc(100%)`,
        alignItems: 'stretch',
        justifyContent: 'space-between',
      }}>
        <Paper sx={{
          overflow: 'auto',
          minWidth: 280,
          padding: '24px',
          textAlign: 'center',
          flex: 1,
          mb: '1px',
          mr: '1px',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}>

            {isLocalhost &&
              <Box>
                <input checked={isLocalhostIframe} onChange={() => handleSetIsLocalhostIframe(!isLocalhostIframe)} type='checkbox' name='localhost' id='localhost' />
                <label htmlFor='localhost' style={{
                  marginLeft: 10,
                }}>
                  Localhost preview
                </label>
              </Box>
            }

            <ExitAttributionUI
              componentConfig={componentConfig as any}
              setComponentConfig={setComponentConfig as any}
            />

            <Box sx={{
              paddingTop: '8px',
              display: 'flex',
              gap: '5px',
              justifyContent: 'space-between',
              alignSelf: 'end',
            }}>
              <Box>
                <Button color='secondary'
                  onClick={() => {
                    if (!hasDiff) {
                      history.push(getRoutePath('components'))
                    }
                    else {
                      const confirmed = window.confirm('Discard changes?')
                      if (confirmed) {
                        history.push(getRoutePath('components'))
                      }
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  variant='contained' color='primary'
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  {loading ? 'Updating...' : 'Update'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper sx={{
          overflow: 'auto',
          minWidth: 500,
          padding: '16px',
          textAlign: 'center',
          flex: 1,
        }}>
          <Resizable
            width={resizeState.width}
            height={resizeState.height}
            minConstraints={[300, 533]}
            maxConstraints={[1000, 1000]}
            onResize={onResize}
            onResizeStart={() => setIsResizing(true)}
            onResizeStop={() => setIsResizing(false)}
          >
            <Box
              className='box'
              sx={{
                margin: 'auto',
                width: `${resizeState.width}px`,
                height: `${resizeState.height}px`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box sx={{
                  mb: 2,
                  ml: 'auto',
                }}>

                  {/* <Box sx={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: '#0096FC',
                  }}>
                    {formatPercent(metric?.surveyCompletion?.value)}
                  </Box> */}

                </Box>
              </Box>

              <Box
                style={{
                  flex: 3,
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                  contain: 'layout',
                  position: 'relative' as any,
                  outline: '1px solid lightgrey',
                }}
              >
                <iframe
                  onLoad={() => setIframeLoaded(true)}
                  ref={iframeRef}
                  title='Live preview'
                  style={{
                    pointerEvents: isResizing ? 'none' : undefined as unknown as any,
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    border: 0,
                    position: 'absolute' as any,
                  }}
                  src={iframeSrc}
                />
              </Box>
            </Box>
          </Resizable>
        </Paper>

      </Box>
    </Box>
  )
}

export default EditExitAttribution
