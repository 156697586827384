import React, { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,   Divider, Grid, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import LanguageIcon from '@mui/icons-material/Language'
import { useTranslation } from 'react-i18next'
import extractFirstTwoLettersOfUsername from 'src/utils/extractFirstTwoLettersOfUsername'
import LanguageSelect from 'src/components/LanguageSelect/LanguageSelect'
import useAuth from 'src/hooks/useAuth'

import type { FC } from 'react'


const PREFIX = 'UserMenu'

const classes = {
  firstLevelContainer: `${PREFIX}-firstLevelContainer`,
  topContainer: `${PREFIX}-topContainer`,
  itemMenu: `${PREFIX}-itemMenu`,
  itemMenuHover: `${PREFIX}-itemMenuHover`,
  content: `${PREFIX}-content`,
  topMenu: `${PREFIX}-topMenu`,
  userName: `${PREFIX}-userName`,
  centerGrid: `${PREFIX}-centerGrid`,
  textCursor: `${PREFIX}-textCursor`,
  adjustIcon: `${PREFIX}-adjustIcon`,
  iconSize: `${PREFIX}-iconSize`,
}

const StyledBox = styled(Box)((
  {
    theme,
  },
) => ({
  [`&.${classes.firstLevelContainer}`]: {
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '4%',
      left: -9,
      transform: 'translate(0, -50%)',
      width: 0,
      height: 0,
      borderTop: '7px solid transparent',
      borderBottom: '7px solid transparent',
      borderRight: '11px solid ' + theme.palette.background.paper,
      zIndex: 1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '4%',
      left: -11,
      transform: 'translate(0, -50%)',
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderRight: '11px solid rgba(63,63,68,0.05)',
      zIndex: 0,
    },
  },

  [`&.${classes.topContainer}`]: {
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 'unset',
      left: 'unset',
      right: '10px',
      transform: 'translate(0, -50%) rotate(340deg)',
      width: 0,
      height: 0,
      borderTop: '7px solid transparent',
      borderBottom: '7px solid transparent',
      borderRight: '11px solid ' + theme.palette.background.paper,
      zIndex: 1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 'unset',
      left: 'unset',
      right: '10px',
      transform: 'translate(0, -50%) rotate(340deg)',
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderRight: '11px solid rgba(63,63,68,0.05)',
      zIndex: 0,
    },
  },

  [`& .${classes.itemMenu}`]: {
    '&:hover .UserMenu-content': {
      display: 'flex',
      width: 'auto',
      height: 'auto',
      opacity: 1,
      overflow: 'visible !important',
      transition: 'opacity 0.3s',
      transform: 'translate(calc(100% + 20px), -15%)',
    },
  },

  [`& .${classes.itemMenuHover}`]: {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },

  [`& .${classes.content}`]: {
    position: 'absolute',
    top: '50%',
    right: '18px',
    fontSize: 14,
    whiteSpace: 'nowrap',
    transform: '-100%, -50%)',
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.background.dark,
    boxShadow: theme.shadows[1],
    borderRadius: 5,
  },

  [`& .${classes.topMenu}`]: {
    right: '560px',
  },

  [`& .${classes.userName}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.brand.blueGrey,
    color: theme.palette.text.white,
    width: 40,
    height: 40,
    fontWeight: 'bold',
    fontSize: 16,
    paddingLeft: 1,
    paddingTop: 1,
  },

  [`& .${classes.centerGrid}`]: {
    width: 50,
    alignSelf: 'center',
  },

  [`& .${classes.textCursor}`]: {
    cursor: 'text',
  },

  [`& .${classes.adjustIcon}`]: {
    top: '60%',
  },

  [`& .${classes.iconSize}`]: {
    width: '12px',
    height: '12px',
  },
}))

interface UserMenuProps {
  name: string
  email: string
  language: string
  languageStorageKey: string
  position?: 'top'|'bottom'
}

const UserMenu: FC<UserMenuProps> = ({ name, email, language, languageStorageKey, position = 'bottom' }) => {
  const {t} = useTranslation()

  const initials = extractFirstTwoLettersOfUsername(name)
  const { logout } = useAuth()

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onClickUrl = useCallback((url: string): () => void => {
    return () => openInNewTab(url)
  }, [])

  return (
    <StyledBox sx={{ width: 'auto', minWidth: 300 }} className={clsx(classes.firstLevelContainer, {[classes.topContainer]: position === 'top'})}>
      <List component="nav" aria-label="user menu">
        <ListItem className={classes.textCursor}>
          <ListItemIcon>
            <Grid container style={{flexWrap: 'inherit'}} direction='row'>
              <Grid className={classes.centerGrid}>
                <span className={classes.userName}>{initials}</span>
              </Grid>
              <Grid>
                <Grid container direction='column'>
                  <Grid item>
                    <Typography variant="h6"
                      color="textPrimary">
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item><span>{email}</span></Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItemIcon>
        </ListItem>

        <ListItem className={clsx(classes.itemMenu, classes.itemMenuHover)}>
          <ListItemIcon>
            { position === 'top' ? <ArrowBackIosIcon className={classes.iconSize} color="primary"/> : <LanguageIcon fontSize="medium"/>}
          </ListItemIcon>
          <ListItemText primary={t('nav menu.switch language')} />
          {<span className={clsx(classes.content, {[classes.topMenu]: position === 'top'})}> <LanguageSelect activeLanguage={language} languageStorageKey={languageStorageKey}/> </span> }
          <ListItemSecondaryAction
            classes={{
              root: classes.adjustIcon,
            }}
          >
            { position === 'top'  ? <LanguageIcon fontSize="medium"/> : <ArrowForwardIosIcon className={classes.iconSize} color="primary"/> }
          </ListItemSecondaryAction>
        </ListItem>

        <Divider />

        <ListItem className={classes.itemMenuHover}>
          <ListItemText primary={t('nav menu.help center')} onClick={onClickUrl('https://support.simplicitydx.com')} />
          <ListItemSecondaryAction
            classes={{
              root: classes.adjustIcon,
            }}
          >
            <OpenInNewIcon className={classes.iconSize} color="primary"/>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem className={classes.itemMenuHover} onClick={onClickUrl('https://twitter.com/simplicitydx')}>
          <ListItemText primary={t('nav menu.status page')} />
          <ListItemSecondaryAction
            classes={{
              root: classes.adjustIcon,
            }}
          >
            <OpenInNewIcon className={classes.iconSize} color="primary"/>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem className={classes.itemMenuHover} onClick={onClickUrl('https://www.simplicitydx.com/privacy-policy')}>
          <ListItemText primary={t('nav menu.terms privacy')} />
          <ListItemSecondaryAction
            classes={{
              root: classes.adjustIcon,
            }}
          >
            <OpenInNewIcon className={classes.iconSize} color="primary"/>
          </ListItemSecondaryAction>
        </ListItem>

        <Divider />

        <ListItem className={classes.itemMenuHover} onClick={logout}>
          <ListItemText primary={t('nav menu.logout')} />
        </ListItem>

      </List>
    </StyledBox>
  )
}

export default UserMenu
