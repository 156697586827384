export interface StoreSettingsPayload {
  colors: {
    primary: string
    secondary: string
    logoBackground: string
  }
  fonts: {
    main: {
      name: string
      url: string
    }
    header: {
      name: string
      url: string
    }
  }
}


export interface CustomDomainPayload {
  domain: string
}

export interface StoreSettingsResponse {
  shop: string
  logoUrl: string
  colors: {
    primary: string
    secondary: string
    logoBackground: string
  }
  fonts: {
    main: {
      name: string
      url: string
    }
    header: {
      name: string
      url: string
    }
  }
}

export interface CustomDomainSettingsResponse {
  name: string
  value: string
  type: string
}


export enum ThirdPartyName {
  Klaviyo = 'Klaviyo',
  PowerReviews = 'Power Reviews'
}

export enum ThirdPartyType {
  Email = 'EMAIL',
  Reviews = 'REVIEW_PLATFORM'
}

export interface KlaviyoConfig {
  apiKey: string
  lists: {
    id: string
    name: string
  }[]
}

export interface PowerReviewsConfig {
  apiKey: string
  merchantId: string
}

export interface ThirdPartyBase {
  name: ThirdPartyName
  type: ThirdPartyType
}

export interface KlaviyoThirdParty extends ThirdPartyBase {
  name: ThirdPartyName.Klaviyo
  type: ThirdPartyType.Email
  config: KlaviyoConfig
}

export interface PowerReviewsThirdParty extends ThirdPartyBase {
  name: ThirdPartyName.PowerReviews
  type: ThirdPartyType.Reviews
  config: PowerReviewsConfig
}

// Union type to represent any third-party
export type ThirdParty = KlaviyoThirdParty | PowerReviewsThirdParty;

export interface TrackingScriptsDataPayload {
  name: string
  content: string
}
export interface TrackingScriptsPayload {
  configName: string
  client: string
  tenant: string
  data: TrackingScriptsDataPayload[]
}

export interface HeadScriptPayload {
  headScript: string
}
