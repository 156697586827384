import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction  } from '@mui/material'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useTranslation } from 'react-i18next'
import type { Store } from 'src/types/auth'

import type { FC } from 'react'
import extractFirstTwoLettersOfWords from 'src/utils/extractFirstTwoLettersOfWords'
import TextIcon from 'src/components/TextIcon/TextIcon'
import SimpleSelect from 'src/components/SimpleSelect/SimpleSelect'

const PREFIX = 'ProjectMenu'

const classes = {
  firstLevelContainer: `${PREFIX}-firstLevelContainer`,
  itemMenuHover: `${PREFIX}-itemMenuHover`,
  itemMenuContent: `${PREFIX}-itemMenuContent`,
  content: `${PREFIX}-content`,
  adjustIcon: `${PREFIX}-adjustIcon`,
  iconSize: `${PREFIX}-iconSize`,
}

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.firstLevelContainer}`]: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '15%',
      left: -9,
      transform: 'translate(0, -50%)',
      width: 0,
      height: 0,
      borderTop: '7px solid transparent',
      borderBottom: '7px solid transparent',
      borderRight: '11px solid ' + theme.palette.background.paper,
      zIndex: 1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '15%',
      left: -11,
      transform: 'translate(0, -50%)',
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderRight: '11px solid rgba(63,63,68,0.05)',
      zIndex: 0,
    },
  },

  [`& .${classes.itemMenuHover}`]: {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },

  [`& .${classes.itemMenuContent}`]: {
    '&:hover .ProjectMenu-content': {
      display: 'flex',
      width: 'auto',
      height: 'auto',
      opacity: 1,
      overflow: 'visible !important',
      transition: 'opacity 0.3s',
      transform: 'translate(calc(100% + 20px), -15%)',
    },
  },

  [`& .${classes.content}`]: {
    position: 'absolute',
    top: '50%',
    right: '18px',
    fontSize: 14,
    whiteSpace: 'nowrap',
    transform: '-100%, -50%)',
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.background.dark,
    boxShadow: theme.shadows[1],
    borderRadius: 5,
  },

  [`& .${classes.adjustIcon}`]: {
    pointerEvents: 'none',
    top: '60%',
  },

  [`& .${classes.iconSize}`]: {
    width: '18px',
    height: '18px',
  },
}))

interface ProjectMenuProps {
  selected: Store
  options: Store[]
  onChange: (tenant: Store) => void
}

const ProjectMenu: FC<ProjectMenuProps> = ({ selected, options, onChange }) => {
  const {t} = useTranslation()

  const initials = extractFirstTwoLettersOfWords(selected)

  const hoverMargin = 10
  return (
    <Root>
      <Box sx={{ minWidth: 300}} className={classes.firstLevelContainer}>
        <List component="nav" aria-label="project menu">
          <ListItem data-testid='projectMenu/nameCard'>
            <ListItemIcon>
              <TextIcon content={initials} width="25px"/>
            </ListItemIcon>
            <ListItemText primary={`${selected}`} />
          </ListItem>

          <ListItem className={clsx(classes.itemMenuContent, classes.itemMenuHover)}>
            <ListItemIcon>
              <SwapHorizIcon fontSize="medium"/>
            </ListItemIcon>
            <ListItemText primary={t('nav menu.switch store')} />
            {
              <span className={classes.content}>
                <div style={{marginLeft: -hoverMargin}}>
                  <div style={{marginLeft: hoverMargin}}>
                    <SimpleSelect selected={selected} options={options} onChange={onChange}/>
                  </div>
                </div>
              </span>
            }
            <ListItemSecondaryAction
              classes={{
                root: classes.adjustIcon,
              }}
            >
              <ArrowForwardIosIcon className={classes.iconSize} color="primary"/>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Box>
    </Root>
  )
}

export default ProjectMenu
