import { MediaPrimitiveContent, CategoryPrimitiveContent, ComponentType, ProductPrimitiveContent, StorefrontPagePrimitiveContent } from 'storefront-interpreter/src/api/components'
import { deepCloneJson } from 'src/utils/helpers'
import { mediaPrimitiveBase, categoryPrimitiveBase, ComponentTypeResponse, getCategoryFields, getExtendedFields, getFullFields, getMinifiedFields, htmlPrimitiveBase, klaviyoPrimitiveBase, productPrimitiveBase, storefrontPagePrimitiveBase, youtubePrimitiveBase } from '../Components/types'
import { v4 as uuidv4 } from 'uuid'
import { StorePrimitiveSettings } from '../ComponentSettingsPage/types'


export const getComponentBase = (componentType: ComponentType, visibleInLibrary?: boolean, storePrimitiveSettings?: StorePrimitiveSettings): ComponentTypeResponse => {
  switch (componentType) {
  case 'SIMILAR_PRODUCTS' : {
    const base: ProductPrimitiveContent = deepCloneJson(productPrimitiveBase)
    base.value.uiSettings.fields = getFullFields()

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'CAROUSEL',
      internalName: '',
      internalDescription: '',
      contentType: 'PRODUCT',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'HOOK',
    }
  }

  case 'VISUAL_COLLECTION': {
    const base: ProductPrimitiveContent = deepCloneJson(productPrimitiveBase)
    base.value.uiSettings.fields = getFullFields()

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'CAROUSEL',
      internalName: '',
      internalDescription: '',
      contentType: 'PRODUCT',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'HOOK',
    }
  }

  case 'ALTERNATES_IN_CATEGORY': {
    const base: ProductPrimitiveContent = deepCloneJson(productPrimitiveBase)
    base.value.apiSettings.filterBy = 'SAME_CATEGORY'
    base.value.uiSettings.fields = getFullFields()
    base.type = 'PRODUCT_PRIMITIVE'

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'CAROUSEL',
      internalName: '',
      internalDescription: '',
      contentType: 'PRODUCT',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'HOOK',
    }
  }

  case 'ALL_PUBLISHED_CAMPAIGNS': {
    const base: StorefrontPagePrimitiveContent = deepCloneJson(storefrontPagePrimitiveBase)
    base.value.uiSettings.fields = getExtendedFields()

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'CAROUSEL',
      internalName: '',
      internalDescription: '',
      contentType: 'STOREFRONT',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'HOOK',
    }
  }

  case 'MANUAL_CATEGORY_NAVIGATION': {
    const base: CategoryPrimitiveContent = deepCloneJson(categoryPrimitiveBase)
    base.value.uiSettings.fields = getCategoryFields()

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'CAROUSEL',
      internalName: '',
      internalDescription: '',
      contentType: 'CATEGORY',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'SAVED',
    }
  }

  case 'MANUAL_PRODUCT_SELECTION': {
    const base: ProductPrimitiveContent = deepCloneJson(productPrimitiveBase)
    base.value.uiSettings.fields = getFullFields()
    base.value.uiSettings.imageFit = storePrimitiveSettings?.product.uiSettings.imageFit

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'CAROUSEL',
      internalName: '',
      internalDescription: '',
      contentType: 'PRODUCT',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'SAVED',
    }
  }

  case 'ALL_PRODUCTS_IN_CATEGORY': {
    // TODO: this component displays product primitives, so we should probably change the primitive type to PRODUCT_PRIMITIVE.
    const base: CategoryPrimitiveContent = deepCloneJson(categoryPrimitiveBase)
    base.value.uiSettings.fields = getMinifiedFields()
    base.value.apiSettings.filterBy = 'IN_CATEGORY'
    base.value.apiSettings.sortBy = 'SALES'
    base.value.apiSettings.sortDir = 'DESC'

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'GRID',
      internalName: '',
      internalDescription: '',
      contentType: 'PRODUCT',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'SAVED',
    }
  }

  case 'HTML':
    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'GRID',
      internalName: '',
      internalDescription: '',
      contentType: 'HTML',
      componentType,
      contentConfiguration: deepCloneJson(htmlPrimitiveBase),
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'SAVED',
    }

  case 'YOUTUBE':
    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'GRID',
      internalName: '',
      internalDescription: '',
      contentType: 'HTML',
      componentType,
      contentConfiguration: deepCloneJson(youtubePrimitiveBase),
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'SAVED',
    }

  case 'EMAIL_CAPTURE': {
    const base = deepCloneJson(klaviyoPrimitiveBase)
    base.value.uiSettings.text = 'Subscribe to our newsletter for exclusive content!'
    base.value.uiSettings.buttonText = 'Subscribe'

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'GRID',
      internalName: '',
      internalDescription: '',
      contentType: 'KLAVIYO',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'INTEGRATION',
    }
  }

  case 'BACK_IN_STOCK_NOTIFICATION':
    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'GRID',
      internalName: '',
      internalDescription: '',
      contentType: 'KLAVIYO',
      componentType,
      contentConfiguration: deepCloneJson(klaviyoPrimitiveBase),
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'INTEGRATION',
    }

  case 'BANNER': {
    const base: MediaPrimitiveContent = deepCloneJson(mediaPrimitiveBase)

    return {
      componentId: uuidv4(),
      createdAt: 123456,
      updatedAt: 123456,
      title: '',
      containerType: 'CAROUSEL',
      internalName: '',
      internalDescription: '',
      contentType: 'MEDIA',
      componentType,
      contentConfiguration: base,
      status: 'PUBLISHED',
      visibleInLibrary: !!visibleInLibrary,
      class: 'SAVED',
    }
  }

  default:
    throw new Error('Unknown component type: ' + componentType)
  }
}
