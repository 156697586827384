import React, { useEffect, useState  } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Checkbox, Container, FormControlLabel, Grid, Link, Tab, useTheme } from '@mui/material'
import { TabPanel, TabList, TabContext } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import Page from 'src/components/Page'
import ProductHeader from './ProductHeader'
import Header from './Header'
import Spacer from 'src/components/Spacer'
import MultiColumnRow from './Cards/MultiColumnRow'
import useGenericContext from 'src/hooks/useGenericContext'
import { useSelector } from 'src/appRedux/store'
import ProductSelector from 'src/components/ProductSelector'
import { formatDataValue, getLift } from 'src/utils/analyticsHelpers'
import HorizontalProgressTable from './Cards/HorizontalProgressTable'
import ErrorPage from 'src/views/ErrorPage'
import { BarElement } from './duck/barElement'
import { DataStat } from 'src/contexts/types'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'
import { logging } from 'src/utils/logging'
import { PageConfig } from 'src/types/interpretor'
import PageMlPerformance, { ComponentInfoDict } from './PageMlPerformance'
import MLPerformanceMetric from './MLPerformanceMetric'
import { PageMetrics } from 'src/contexts/api/getPageMetrics'
import { getRoutePath } from 'src/routes'
import { MetaDownloadModal } from './MetaDownloadModal'

const colors = [
  '#FFF9C4',
  '#C8E6C9',
  '#B2EBF2',
  '#C5CAE9',
  '#F8BBD0',
  '#FFCCBC',
  '#f5f5f5',
  '#cfd8dc',
  '#c5cae9',
  '#c8e6c9',
  '#90ee90',
  '#00ffaa',
  '#00ffff',
  '#68cffc',
  '#63a0ff',
  '#b2ebf2',
  '#e0ffff',
  '#d3d3d3',
  '#a9a9a9',
  '#008b8b',
  '#bdb76b',
  '#ff686b',
  '#808000',
  '#556b2f',
  '#004400',
  '#008000',
  '#ff8c00',
  '#ffafff',
  '#4b0082',
  '#a063ec',
  '#9932cc',
  '#ff00ff',
  '#a267a6',
  '#ffff00',
  '#ffd700',
  '#00ff00',
  '#ff0000',
  '#800000',
  '#000000',
  '#0000ff',
  '#ffffff',
]

const PREFIX = 'CampaignAnalytics'

const classes = {
  root: `${PREFIX}-root`,
  breadcrumb: `${PREFIX}-breadcrumb`,
}

enum TabIndex {
  Overview = '1',
  AIOptimization = '2',
}

const StyledPage = styled(Page)((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    '& .MuiGrid-item': {
      maxWidth: '100%',
    },
  },

  [`& .${classes.breadcrumb}`]: {
    paddingBottom: theme.spacing(2),
  },
}))

interface CampaignAnalyticsData {
  campaigns: BarElement[]
  mediums: BarElement[]
  sources: BarElement[]
}

const CampaignAnalytics: FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const {t} = useTranslation()
  const date = useSelector((state) => state.date)
  const { statusBasedPageConfigs, getPerformanceAnalytics, getCampaignAnalytics, getOptimalPage, getManualPage, currencyCode, getPageConfig, getPageMetrics, isBrand, homeCustomDate} = useGenericContext()

  const [pageError, setPageError] = useState(false)
  const [data, setData] = useState<Partial<DataStat>>({})
  const [baseData, setBaseData] = useState<Partial<DataStat>>({})
  const [dataLift, setDataLift] = useState<Partial<any>>({})
  const [tableError, setTableError] = useState(false)

  const [campaignAnalyticsData, setCampaignAnalyticsData] = useState<CampaignAnalyticsData>({campaigns: [], mediums: [], sources: []})
  const [loadingCampaignAnalytics, setLoadingCampaignAnalytics] = useState<boolean>(true)
  const [campaignAnalyticsError, setCampaignAnalyticsError] = useState<boolean>(false)

  const [optimalPage, setOptimalPage] = useState<any>()
  const [manualPage, setManualPage] = useState<any>()
  const [pageMetrics, setPageMetrics] = useState<{manualPageMetrics: PageMetrics|undefined, optimalPageMetrics : PageMetrics|undefined}>({
    manualPageMetrics: undefined,
    optimalPageMetrics: undefined,
  })
  const [componentInfoDict, setComponentInfoDict] = useState<ComponentInfoDict>({})
  const [loadingMlPages, setLoadingMlPages] = useState<boolean>(true)

  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [pageConfig, setPageConfig] = useState<PageConfig>()
  const searchParams = useParams<{id: string}>()

  const [tabIndex, setTabIndex] = React.useState(TabIndex.AIOptimization)

  const [isMetaDownloadOpen, setIsMetaDownloadOpen] = useState<boolean>(false)
  const isLocalhostIframeName = 'isLocalhostIframe_ML'
  const [isLocalhost, setIsLocalhost] = useState(window.location.hostname === 'localhost')
  const [isLocalhostIframe, setIsLocalhostIframe] = useState(localStorage.getItem(isLocalhostIframeName) === 'true')

  const handleSetIsLocalhostIframe = ((val: boolean) => {
    setIsLocalhostIframe(val)
    localStorage.setItem(isLocalhostIframeName, val ? 'true' : '')
  })


  useEffect(() => {
    const doAsync = async () => {
      try {
        const newPageConfig = await getPageConfig(searchParams.id)
        if (newPageConfig) setPageConfig(newPageConfig)
      }
      catch (e) {
        logging(e, { tags: { section: 'CampaignAnalytics getPageConfig' + searchParams.id}})
        setPageError(true)
      }
    }
    doAsync()
  }, [searchParams.id, getPageConfig])

  useEffect(() => {
    if (!pageConfig || tabIndex !== TabIndex.Overview) return

    const doAsync = async () => {
      try {
        const storefrontId = pageConfig.id || ''
        const startTimestamp = date.timeRange.start
        const endTimestamp = date.timeRange.end

        const response = await getCampaignAnalytics(storefrontId, isBrand, startTimestamp, endTimestamp)
        const campaignAnalyticsDataResponse = response?.data
        campaignAnalyticsDataResponse && setCampaignAnalyticsData(campaignAnalyticsDataResponse)
        setLoadingCampaignAnalytics(false)
      } catch (e) {
        console.warn('Error fetching campaign analytics', e)
        setCampaignAnalyticsError(true)
        setCampaignAnalyticsData({campaigns: [], mediums: [], sources: []})
        setLoadingCampaignAnalytics(false)
        logging(e, { tags: { section: 'CampaignAnalytics'}})
      }
    }
    doAsync()
  }, [date, pageConfig, getCampaignAnalytics, tabIndex, isBrand])

  useEffect(() => {
    if (!pageConfig || tabIndex !== TabIndex.Overview) return

    const doAsync = async () => {
      try {
        const storefrontId = pageConfig.id || ''
        const startTimestamp = date.timeRange.start
        const endTimestamp = date.timeRange.end

        const response = await getPerformanceAnalytics(storefrontId, isBrand, startTimestamp, endTimestamp)

        if (response?.data && !response.data?.[storefrontId] ) {
          response.data[storefrontId] = {
            aov: 0,
            bounceRate: 0,
            campaignRevenue: 0,
            conversionRate: 0,
            pageViews: 0,
            revenuePerVisit: 0,
          }
        }
        if (response?.data && !response.data?.['base'] ) {
          response.data['base'] = {
            aov: 0,
            bounceRate: 0,
            campaignRevenue: 0,
            conversionRate: 0,
            pageViews: 0,
            revenuePerVisit: 0,
          }
        }

        if (response?.data && response?.data?.[storefrontId] ) {

          const storefrontData: DataStat = response?.data?.[storefrontId]

          // convert to percentages
          storefrontData.bounceRate = storefrontData.bounceRate * 100
          storefrontData.conversionRate = storefrontData.conversionRate * 100
          setData(storefrontData)

          if (response?.data?.['base']) {
            const baseStorefrontData: DataStat = response?.data?.['base']
            // convert to percentages
            baseStorefrontData.bounceRate = baseStorefrontData.bounceRate * 100
            baseStorefrontData.conversionRate =  baseStorefrontData.conversionRate * 100
            setBaseData(baseStorefrontData)

            setDataLift({
              aov: getLift(storefrontData?.aov, baseStorefrontData?.aov),
              bounce_rate: getLift(storefrontData?.bounceRate, baseStorefrontData?.bounceRate),
              campaign_revenue: getLift(storefrontData?.campaignRevenue, baseStorefrontData?.campaignRevenue),
              conversion_rate: getLift(storefrontData?.conversionRate, baseStorefrontData?.conversionRate),
              page_views: getLift(storefrontData?.pageViews, baseStorefrontData?.pageViews),
              revenue_per_visit: getLift(storefrontData?.revenuePerVisit, baseStorefrontData?.revenuePerVisit),
            })
          }

        }
        else {
          setData({})
          setBaseData({})
          setDataLift({})
        }
        setDataLoading(false)
      }
      catch (e) {
        console.log({ error: e })
        console.warn('Error with performance analytics', e)
        setTableError(true)
        setData({})
        setBaseData({})
        setDataLift({})
        setDataLoading(false)
        logging(e, { tags: { section: 'CampaignAnalytics'}})
      }
    }

    doAsync()
  }, [pageConfig, date, getPerformanceAnalytics, tabIndex, isBrand])

  useEffect(() => {
    if (!pageConfig || tabIndex !== TabIndex.AIOptimization) return

    const doAsync = async () => {
      try {
        const storefrontId = pageConfig.id || ''

        const [manualPageResponse, optimalPageResponse] = await Promise.all([
          getManualPage(storefrontId), getOptimalPage(storefrontId),
        ])

        const newManualPage = manualPageResponse?.data[0]
        const newOptimalPage = optimalPageResponse?.data.page

        const newManualPageComponentOrderId = newManualPage?.componentOrderId
        const newOptimalPageComponentOrderId = newOptimalPage?.componentOrderId

        const startTimestamp = date.timeRange.start
        const endTimestamp = date.timeRange.end

        if (newManualPageComponentOrderId && newOptimalPageComponentOrderId) {
          try {

            const [manualPageMetricsResponse, optimalPageMetricsResponse] = await Promise.all([
              getPageMetrics(storefrontId, newManualPageComponentOrderId, isBrand, startTimestamp, endTimestamp, 'USER_DEFINED'),
              getPageMetrics(storefrontId, newOptimalPageComponentOrderId, isBrand, startTimestamp, endTimestamp, 'OPTIMAL'),
            ])

            const manualPageMetrics = manualPageMetricsResponse?.data
            const optimalPageMetrics = optimalPageMetricsResponse?.data
            setPageMetrics({manualPageMetrics, optimalPageMetrics})

          } catch (e) {
            console.warn('Error fetching page metrics', e)
            logging(e, { tags: { section: 'Error fetching AI page metrics'}})
          }
        }


        if (newOptimalPage) newOptimalPage.componentIds = newOptimalPage?.components.map(x => x.componentId)

        if (newManualPage) setManualPage(newManualPage)
        if (newOptimalPage) setOptimalPage(newOptimalPage)

        if (newManualPage) {
          const newComponentInfoDict: ComponentInfoDict = {}
          newManualPage.components.forEach((component, i) => {
            const componentMetric = optimalPageResponse?.data.componentMetrics.find(x => x.componentId === component.componentId)
            newComponentInfoDict[component.componentId] = {
              color: colors[i],
              componentMetric: componentMetric?.metrics|| [],
              isFixed: componentMetric?.isFixed || false,
            }
          })
          setComponentInfoDict(newComponentInfoDict)
        }

        setLoadingMlPages(false)
      }
      catch (e) {
        console.warn('Error fetching optimal/manual page', e)

        setManualPage(undefined)
        setOptimalPage(undefined)

        setLoadingMlPages(false)
        logging(e, { tags: { section: 'Set optimal/manual page'}})
      }
    }
    doAsync()
  }, [date, pageConfig, getCampaignAnalytics, tabIndex, getOptimalPage, getManualPage, getPageMetrics, isBrand])

  if (pageError) {
    return <ErrorPage mainText='There is an error loading the page' subText='There was an error loading the page you are trying to view' />
  }

  const toggleMetaDownloadModal = () => {
    setIsMetaDownloadOpen(!isMetaDownloadOpen)
  }

  return (
    <StyledPage className={classes.root} title={t('Campaign analytics')}>
      <Container fixed>
        <Grid className={classes.breadcrumb} container spacing={1}>
          <Grid item>
            <Link onClick={() => history.push(getRoutePath('stores'))}>Campaign Stores /</Link>
          </Grid>
          <Grid item style={{transform: 'translateY(-16px)', minWidth: 350}}>
            <ProductSelector
              items={statusBasedPageConfigs.map ((page: PageConfig) => {
                return ({
                  id: page.id,
                  title: page.internalName,
                  images: page.campaignMedias.slice(0, 1) || [],
                })
              })}
              selected={{
                id: pageConfig?.id || '',
                title: pageConfig?.internalName || '',
                images: pageConfig?.campaignMedias.slice(0, 1) || [],
              }}
              onSelect={(item) => {
                history.push(getRoutePath('store_analytics', {id: item.id}))
              }}
            />
          </Grid>
          <Grid item><div>/ Analytics</div></Grid>
        </Grid>

        <ProductHeader
          // TODO: if no campaign image, fetch product
          imgSrc={pageConfig?.campaignMedias?.[0]?.mediumUrl || ''}
          mainHeaderFirstLine={pageConfig?.internalName || ''}
          mainHeaderSecondLine={pageConfig?.subheadline}
          subHeaderFirstLine=''
          subHeaderSecondLine=''
          linkDescription='PUBLIC LINK'
          // TODO: rename on backend to storefront
          link={pageConfig?.pageUrl || ''}
        />
        <Spacer space={5}/>
        <TabContext value={tabIndex}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <TabList onChange={(e, val) => setTabIndex(val)} aria-label='Campaign analytics tabs'>
                {/* <Tab label='Overview' value={TabIndex.Overview} /> */}
                <Tab label='AI Optimization' value={TabIndex.AIOptimization} />
              </TabList>
            </Grid>
            <Grid item>
              <Header
                toggleMetaDownloadModal={toggleMetaDownloadModal}
                pageId={searchParams.id}
              />
              <Box>
                <MetaDownloadModal
                  isOpen={isMetaDownloadOpen}
                  startTimestamp={homeCustomDate[0]}
                  endTimestamp={homeCustomDate[1]}
                  toggleModal={toggleMetaDownloadModal}
                />
              </Box>
            </Grid>
          </Grid>
          <TabPanel value={TabIndex.Overview} style={{padding: 0, marginTop: 20}}>
            <Grid container
              // spacing={3}
              justifyContent='space-between'
              direction='row'>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <HorizontalProgressTable
                  title={t('TOP UTM MEDIUMS')}
                  icon='UtmCampaign'
                  data={ {
                    elements: campaignAnalyticsData.mediums,
                    page: 1,
                    limit: 10,
                    total: 10}}
                  handlePagination={f => f}
                  isLoading={loadingCampaignAnalytics}
                  error={campaignAnalyticsError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <HorizontalProgressTable
                  title={t('TOP UTM SOURCES')}
                  icon='UtmCampaign'
                  data={ {
                    elements: campaignAnalyticsData.sources,
                    page: 1,
                    limit: 10,
                    total: 10}}
                  handlePagination={f => f}
                  isLoading={loadingCampaignAnalytics}
                  error={campaignAnalyticsError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <HorizontalProgressTable
                  title={t('TOP UTM CAMPAIGNS')}
                  icon='UtmCampaign'
                  data={ {
                    elements: campaignAnalyticsData.campaigns,
                    page: 1,
                    limit: 10,
                    total: 10}}
                  handlePagination={f => f}
                  isLoading={loadingCampaignAnalytics}
                  error={campaignAnalyticsError}
                />
              </Grid>
            </Grid>

            <Spacer space={5}/>
            <MultiColumnRow
              title='CAMPAIGN PERFORMANCE'
              data={
                [
                  { title: 'Views', value: formatDataValue(data.pageViews, 1) },
                  { title: 'Conversion Rate', value: formatDataValue(data.conversionRate, 2, undefined, '%'), lift: dataLift.conversion_rate !== undefined ? dataLift.conversion_rate.toFixed(0): undefined, direction: dataLift.conversion_rate > 0 ? 'positive': 'negative' },
                  { title: 'Campaign Revenue', value: formatDataValue(data.campaignRevenue, 1,currencySymbolMap[currencyCode]), lift: dataLift.campaign_revenue !== undefined ? dataLift.campaign_revenue.toFixed(0): undefined, direction: dataLift.campaign_revenue > 0 ? 'positive' : 'negative' },
                  { title: 'Bounce Rate', value: formatDataValue(data.bounceRate, 2, undefined, '%'), lift: dataLift.bounce_rate !== undefined ? -dataLift.bounce_rate.toFixed(0): undefined, direction: dataLift.bounce_rate < 0 ? 'positive' : 'negative' },
                  { title: 'AOV', value: formatDataValue(data.aov, 1, currencySymbolMap[currencyCode]), lift: dataLift.aov !== undefined ? dataLift.aov.toFixed(0): undefined, direction: dataLift.aov > 0 ? 'positive' : 'negative' },
                  { title: 'Revenue/Visit', value: formatDataValue(data.revenuePerVisit, 1, currencySymbolMap[currencyCode]), lift: dataLift.revenue_per_visit !== undefined ? dataLift.revenue_per_visit.toFixed(0): undefined, direction: dataLift.revenue_per_visit > 0 ? 'positive' : 'negative' },
                ]
              }
              isLoading={dataLoading}
              error={tableError}/>
            <Spacer space={5}/>
            <MultiColumnRow
              title='BASELINE PERFOMANCE'
              subTitle='Social Traffic Landing on Brand Site'
              data={
                [
                  { title: 'Views', value: formatDataValue(baseData.pageViews, 1) },
                  { title: 'Conversion Rate', value: formatDataValue(baseData.conversionRate, 2, undefined, '%') },
                  { title: 'Campaign Revenue', value: formatDataValue(baseData.campaignRevenue, 1, currencySymbolMap[currencyCode]) },
                  { title: 'Bounce Rate', value: formatDataValue(baseData.bounceRate, 2, undefined, '%') },
                  { title: 'AOV', value: formatDataValue(baseData.aov, 1, currencySymbolMap[currencyCode]) },
                  { title: 'Revenue/Visit', value: formatDataValue(baseData.revenuePerVisit, 1, currencySymbolMap[currencyCode]) },
                ]
              }
              isLoading={dataLoading}
              error={tableError}
            />
          </TabPanel>

          {!isLocalhost ? '' :
            <Box>
              <FormControlLabel
                label='localhost iframe'
                control={
                  <Checkbox
                    checked={isLocalhostIframe}
                    onChange={(e) => handleSetIsLocalhostIframe(e.target.checked)}
                  />
                }
              />
            </Box>
          }

          <TabPanel value={TabIndex.AIOptimization}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 0,
              // marginTop: 20,
              minWidth: '1140px',
            }}
          >
            <Box sx={{flex: 1, display: 'flex', gap: 1, my: 2}}>
              <MLPerformanceMetric
                title="Manually Created Experience"
                hardBounce={pageMetrics.manualPageMetrics?.bounceRate}
                averagePageViews={pageMetrics.manualPageMetrics?.avgPageViews}
              />
              <MLPerformanceMetric
                title="AI Optimized Experience"
                hardBounce={pageMetrics.optimalPageMetrics?.bounceRate}
                averagePageViews={pageMetrics.optimalPageMetrics?.avgPageViews}
              />
            </Box>

            <Box
              sx={{
                pt: 1.5,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: theme.palette.system.grey9,
              }}
            >
              <PageMlPerformance orientation='left' title='Starting Point' pageConfig={manualPage} componentInfoDict={componentInfoDict} pageMetrics={pageMetrics.manualPageMetrics} isLocalhost={isLocalhost && isLocalhostIframe} />
              <Box style={{marginLeft: '20px'}}>
                <PageMlPerformance orientation='right' title='Current Winning Point' pageConfig={optimalPage} componentInfoDict={componentInfoDict} pageMetrics={pageMetrics.optimalPageMetrics} isLocalhost={isLocalhost && isLocalhostIframe} />
              </Box>
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </StyledPage>
  )
}

export default CampaignAnalytics
