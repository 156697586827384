import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import type { FC, ReactNode } from 'react'
import {   Box } from '@mui/material'

import NavBar from './NavBar'
import useAuth from 'src/hooks/useAuth'
import { useTranslation } from 'react-i18next'
import Backdrop from '@mui/material/Backdrop'
import { getDeviceType } from 'src/utils/helpers'
import { Alert } from '@mui/material'
import { ReactComponent as Question } from 'src/assets/icons/Question.svg'
const PREFIX = 'AuthLayout'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },

  [`& .${classes.wrapper}`]: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },

  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },

  [`& .${classes.content}`]: {
    flex: '1 1 auto',
    height: '100%',
  },
}))

interface AuthLayoutProps {
  children?: ReactNode
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {

  const {t} = useTranslation()
  const [deviceErrorOpen, setDeviceErrorOpen] = useState(false)
  const [device, setDevice] = useState('')
  const { stores } = useAuth()
  const projectErrorOpen = stores.length < 1

  useEffect(() => {
    const deviceType = getDeviceType()
    if (deviceType !== 'Desktop') {
      setDeviceErrorOpen(true)
      setDevice(deviceType)
    }
  }, [])

  const DeviceErrorBanner = () => <Root>
    {!projectErrorOpen && <NavBar />}
    <Backdrop style={{ zIndex: 100, background: '#FFF' }}
      open={deviceErrorOpen}>
      <NavBar />
      <Box style={{ display: 'grid', placeItems: 'center' }}>
        <Box>
          <Question />
        </Box>

        <Box style={{ width: '70%' }}>
          <Alert
            style={{ color: '#00A5D9', background: 'transparent' }}
            severity="info"
          >
            {t('general.device error', { device: device })}
          </Alert>
        </Box>
      </Box>
    </Backdrop>
  </Root>

  const noErrors = !(deviceErrorOpen)
  return (
    <Fragment>
      <div className={classes.root}>
        { deviceErrorOpen && DeviceErrorBanner() }
        { noErrors ?
          <Fragment>
            <NavBar />
            <div className={classes.wrapper}>
              <div className={classes.contentContainer}>
                <div id="scroll-container" className={classes.content}>{children}</div>
              </div>
            </div>
          </Fragment> : t('general.unknown error')}
      </div>
    </Fragment>
  )
}

export default AuthLayout
