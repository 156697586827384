import React from 'react'
import { styled } from '@mui/material/styles'
import { Button, Grid, Typography  } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

import type { FC } from 'react'
import DatePicker from 'src/components/datePicker'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useGenericContext from 'src/hooks/useGenericContext'

const PREFIX = 'Header'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
}

const StyledGrid = styled(Grid)((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.header}`]: {
    fontSize: 18,
    marginBottom: 14,
  },
}))

interface HeaderProps {
  className?: string
  text?: string
  toggleMetaDownloadModal: () => void
  pageId: string
}

export function downloadURI(uri: string, name: string) {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const Header: FC<HeaderProps> = ({ className, text, toggleMetaDownloadModal, pageId, ...rest }) => {
  const { Meta_download } = useFlags()
  const { setHomeCustomDate, getPageComponentMlMetrics } = useGenericContext()

  const downloadComponentMlMetrics = (async () => {
    const componentMlDownloadUrl = await getPageComponentMlMetrics(pageId)
    componentMlDownloadUrl?.data.downloadUrl && downloadURI(componentMlDownloadUrl?.data.downloadUrl, 'componentMetrics.json')
  })

  return (
    <StyledGrid
      container
      justifyContent="space-between"
    >
      { text && <Grid item>
        <Typography component='span' style={{fontWeight: 500}}
          color="textPrimary"
          className={classes.header}>
          {text}
        </Typography>
      </Grid>}

      {Meta_download && <Grid sx={{
        alignContent: 'center',
        display: 'flex',
        marginRight: '8px',
        cursor: 'pointer',
      }} item>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => downloadComponentMlMetrics()}
          endIcon={<DownloadIcon />}
          sx={{
            backgroundColor: '#F0F5FE',
            color: '#344563',
            fontWeight: '500',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#E9EDF0',
            },
            height: '40px',
            marginRight: '8px',
          }}>
          Component ML Metrics
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleMetaDownloadModal()}
          endIcon={<DownloadIcon />}
          sx={{
            backgroundColor: '#F0F5FE',
            color: '#344563',
            fontWeight: '500',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#E9EDF0',
            },
            height: '40px',
          }}>
          Meta
        </Button>
      </Grid>}

      <Grid item>
        <DatePicker
          getFullDays
          isDayMinimum
          onCustomDateChange={setHomeCustomDate}
          sxStyle={{
            '& path': {
              stroke: 'transparent',
              fill: 'white',
            },
          }}/>
      </Grid>
    </StyledGrid>
  )
}


export default Header
