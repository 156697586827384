import React, {FC, useEffect, useState} from 'react'
import {Box, Button, Paper} from '@mui/material'
import {ComponentSettingsUI} from './ComponentSettingsPage'
import {StorePrimitiveSettings} from './types'
import useGenericContext from '../../hooks/useGenericContext'
import {getRoutePath} from '../../routes'
import {useHistory} from 'react-router-dom'

const ComponentSettingsPage: FC = () => {
  const history = useHistory()

  const { storePrimitiveSettings, saveStorePrimitiveSettings, fetchStorePrimitiveSettings } = useGenericContext()
  const [originalShopPrimitiveSettings] = useState<StorePrimitiveSettings | undefined>(storePrimitiveSettings)
  const [shopPrimitiveSettings, setShopPrimitiveSettings] = useState<StorePrimitiveSettings | undefined>(storePrimitiveSettings)

  const [loading, setLoading] = useState(false)
  const [hasDiff, setHasDiff] = useState(false)

  useEffect(() => {
    if (originalShopPrimitiveSettings && shopPrimitiveSettings) {
      setHasDiff(JSON.stringify(originalShopPrimitiveSettings) !== JSON.stringify(shopPrimitiveSettings))
    }
  }, [originalShopPrimitiveSettings, shopPrimitiveSettings])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (shopPrimitiveSettings) {
        const response = await saveStorePrimitiveSettings(shopPrimitiveSettings)

        if (response?.status === 201) {
          await fetchStorePrimitiveSettings()
          setLoading(false)
        } else {
          window.alert('Error updating component settings')
          console.error('Error updating component settings')
          setLoading(false)
        }
      } else {
        window.alert('Error updating component settings - no settings to send')
        console.error('Error updating component settings - no settings to send')
        setLoading(false)
      }
    }
    catch (error) {
      window.alert('Error updating component settings')
      console.error('Error updating component settings')
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        height: `calc(100%)`,
        alignItems: 'stretch',
        justifyContent: 'space-between',
      }}>
        <Paper sx={{
          overflow: 'auto',
          minWidth: 280,
          padding: '24px',
          textAlign: 'center',
          flex: 1,
          mb: '1px',
          mr: '1px',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}>

            <ComponentSettingsUI
              primitiveSettings={shopPrimitiveSettings as any}
              setPrimitiveSettings={setShopPrimitiveSettings as any}
            />

            <Box sx={{
              paddingTop: '8px',
              display: 'flex',
              gap: '5px',
              justifyContent: 'space-between',
              alignSelf: 'end',
            }}>
              <Box>
                <Button color='secondary'
                  onClick={() => {
                    if (!hasDiff) {
                      history.push(getRoutePath('components'))
                    }
                    else {
                      const confirmed = window.confirm('Discard changes?')
                      if (confirmed) {
                        history.push(getRoutePath('components'))
                      }
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  variant='contained' color='primary'
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  {loading ? 'Updating...' : 'Update'}
                </Button>
              </Box>
            </Box>

          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default ComponentSettingsPage
