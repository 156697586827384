import React, { createContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import type { ReactElement } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Drawer, List,   Divider, Grid } from '@mui/material'
// ICONS
// import { ReactComponent as UserSettingsIcon } from 'src/assets/icons/UserSettingsIcon.svg'
import { ReactComponent as FullLogoSvg } from 'src/assets/icons/Full_logo.svg'
import { ReactComponent as SdxmenuClosed } from 'src/assets/icons/SdxmenuClosed.svg'
import { ReactComponent as SdxmenuOpen } from 'src/assets/icons/SdxmenuOpen.svg'

import ProjectMenu from 'src/components/ProjectMenu/ProjectMenu'
import UserMenu from 'src/components/UserMenu/UserMenu'
import useAuth from 'src/hooks/useAuth'
import NavItem from './NavItem'
// import { useTranslation } from 'react-i18next'
import {languageStorageKey, language} from 'src/i18n'
import extractFirstTwoLettersOfWords from 'src/utils/extractFirstTwoLettersOfWords'
import TextIcon from 'src/components/TextIcon/TextIcon'
import { extractStoreNameFromUrl } from 'src/utils/storeName'
import { NavbarRoute, getNavbarRoutes } from 'src/routes'
import useGenericContext from 'src/hooks/useGenericContext'
import { useFlags } from 'launchdarkly-react-client-sdk'

const PREFIX = 'NavBar'

const classes = {
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  avatar: `${PREFIX}-avatar`,
  root: `${PREFIX}-root`,
  scrollbar: `${PREFIX}-scrollbar`,
  logo: `${PREFIX}-logo`,
  logoBox: `${PREFIX}-logoBox`,
  padding: `${PREFIX}-padding`,
  bottomList: `${PREFIX}-bottomList`,
  openMenu: `${PREFIX}-openMenu`,
  closeMenu: `${PREFIX}-closeMenu`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  '.MuiDrawer-paper': {
    width: 'fit-content',
    top: 0,
    height: '100%',
    overflow: 'visible',
  },

  [`& .${classes.avatar}`]: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },

  [`& .${classes.root}`]: {
    padding: 0,
  },

  [`& .${classes.scrollbar}`]: {
    paddingTop: '15px',
    overflow: 'visible!important',
    height: '100%',
  },

  [`& .${classes.logo}`]: {
    width: 103,
    marginRight: '20px',
  },

  [`& .${classes.logoBox}`]: {
    height: '60px',
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  [`& .${classes.padding}`]: {
    padding: 0,
  },

  [`& .${classes.bottomList}`]: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },

  [`& .${classes.openMenu}`]: {
    marginLeft: '10px',
    marginRight: '10px',
  },

  [`& .${classes.closeMenu}`]: {
    marginTop: '2px',
    marginBottom: '2px',
  },
}))

export const NavbarContext = createContext<{ menuExpanded: boolean }>({ menuExpanded: false })
interface Item {
  onClick?: () => void
  href?: string
  icon?: React.ComponentType
  info?: React.ComponentType
  items?: Item[]
  userName?: string
  content?: any
  title: string
  hideTooltip?: boolean
  position?: 'top' | 'bottom'
  showArrow?: boolean
  id?: string
}

interface Section {
  items: Item[]
  subheader: string
  className: string
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[]
  pathname: string
  depth?: number
  }): ReactElement {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
    </List>
  )
}

function reduceChildRoutes({acc, pathname, item, depth}: {
  acc: ReactElement[]
  pathname: string
  item: Item
  depth: number
}): ReactElement[] {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        hideTooltip={item.hideTooltip}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        userName={item.userName}
        position={item.position}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        hideTooltip={item.hideTooltip}
        onClick={item.onClick}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        userName={item.userName}
        content={item.content}
        position={item.position}
        showArrow={item.showArrow}
      />,
    )
  }

  return acc
}

const NavBar = (): JSX.Element => {

  // const {t} = useTranslation()
  const location = useLocation()
  const [ menuExpanded, setMenuExpanded ] = useState(false)
  const { user, stores, selectedStore, setStore } = useAuth()
  const [navbarRoutes, setNavbarRoutes] = useState<NavbarRoute[]>([])
  const flag = useFlags()
  const { merchantDetails } = useGenericContext()

  useEffect(() => {
    const items = getNavbarRoutes(flag, 'standalone')
    setNavbarRoutes(items)
  }, [flag])


  const sections: Section[] = [
    {
      subheader: 'Reports',
      className: classes.padding,
      items: [
        {
          content: (): JSX.Element => <ProjectMenu selected={selectedStore} options={stores} onChange={setStore}/>,
          icon: (): JSX.Element => {
            const initials = extractFirstTwoLettersOfWords(selectedStore)
            return (
              <TextIcon content={initials} width="35px" padding="3px" />
            )
          },
          title: `${extractStoreNameFromUrl(selectedStore)}`,
          hideTooltip: true,
          showArrow: true,
        },
        ...navbarRoutes,
      ],
    },
    {
      subheader: 'Auth',
      className: classes.bottomList,
      items: [{
        title: '',
      },
      {
        hideTooltip: true,
        title: `${user?.name.length < 15 ? user?.name : user?.name.substring(0, 15)+'...' }`,
        content: (): JSX.Element => <UserMenu name={user.name} email={user.email} language={language} languageStorageKey={languageStorageKey}  />,
        userName: user.name,
        position: 'bottom',
        showArrow: true,
      },
      ],
    },
  ]

  const toggleMenu = () => setMenuExpanded(!menuExpanded)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Root
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        className={classes.logoBox}
        onClick={toggleMenu}
      >
        {menuExpanded ?
          <Grid
            className={classes.openMenu}
            container
            justifyContent='space-between'
          >
            <Grid item><FullLogoSvg className={classes.logo}/></Grid>
            <Grid item><SdxmenuOpen /></Grid>
          </Grid> :
          <span className={classes.closeMenu}>
            <SdxmenuClosed />
          </span>
        }
      </Box>

      <Divider />

      <PerfectScrollbar
        className={classes.scrollbar}
        options={{ suppressScrollX: true, suppressScrollY: true }}
      >
        <Box className={classes.root}>
          {sections.map((section) => (
            <List key={section.subheader}
              className={section.className}>
              {renderNavItems({
                items: section.items.map (item => {
                  const newItem = {...item}
                  if (menuExpanded) newItem.hideTooltip = true
                  return newItem
                }).filter( (item) => item.id === 'home' || !(merchantDetails?.plan === undefined || merchantDetails?.plan === 'NONE')),
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Root>
  )

  return (
    <Root>
      <Drawer
        anchor="left"
        open
        variant="persistent"
      >
        <NavbarContext.Provider value={{ menuExpanded: menuExpanded }}>
          {content}
        </NavbarContext.Provider>
      </Drawer>
    </Root>
  )
}

export default NavBar
