import React from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@mui/material'
import type { FC } from 'react'
import Checkmark from 'src/components/Checkmark/Checkmark'
import Icon from 'src/components/Icon/Icon'

interface LanguageSelectProps {
  activeLanguage: string
  languageStorageKey: string
}

const LanguageSelect: FC<LanguageSelectProps> = ({ activeLanguage, languageStorageKey }) => {
  const handleToggle = (value: string) => () => {
    if (value !== activeLanguage) {
      localStorage.setItem(languageStorageKey, value)
      ;(window.location as Location).reload()
    }
  }

  const languageOptions = [{ language: 'English', languageKey: 'en', languageFlag: 'GB' }]

  return (
    <Box sx={{ minWidth: 238 }}>
      <List component="nav" aria-label="select language">
        {languageOptions.map((value) => {
          const { language, languageKey, languageFlag } = value

          return ( <ListItem key={language} data-testid={`languageSelect/${language}`} button onClick={handleToggle(languageKey)}>
            <ListItemIcon>
              <Icon
                name={languageFlag}
                root={true}
              />
            </ListItemIcon>
            <ListItemText primary={`${language}`} />
            <ListItemSecondaryAction>
              <Checkmark visible={activeLanguage === languageKey}/>
            </ListItemSecondaryAction>
          </ListItem>)
        })}
      </List>
    </Box>
  )
}

export default LanguageSelect
